<div class="message-dialog" [ngClass]="{payment: data.flag == 'payment'}">
  <!-- dialog logo  -->
  <img src="../../../../assets/svg/Group 4431.svg" alt="success mark" />
  <div class="message-dialog__content">
    <!-- dialog message  -->
    <div class="message-dialog__content__message">
      {{ data.message }}
    </div>
    <!-- dialog application number  -->
    <div class="message-dialog__content__application-no">
      <p *ngIf="data.flag == 'payment'">
        {{ data.application_no }}
      </p>

      <mat-icon
        [ngClass]="{
          payment: data.flag == 'payment'
        }"
        matTooltipClass="tooltip-payment"
        [matTooltip]="isCopied && data.flag == 'payment' ? 'تم النسخ' : ''"
        matTooltipPosition="above"
        aria-hidden="false"
        *ngIf="!isCopied && data.type != 'exemption' && data.type != 'paymentSubscription'"
        aria-label="Example contect_copy icon"
        (click)="copyToClipboard($event)"
        class="material-icons-outlined"
        >content_copy</mat-icon
      >
      <mat-icon
        aria-hidden="false"
        *ngIf="isCopied && (!data.flag || data.flag == 'payment')"
        aria-label="Example task icon"
        (click)="copyToClipboard($event)"
        class="material-icons-outlined active"
        >task_alt</mat-icon
      >
      <p *ngIf="data.flag != 'payment' && data.type != 'paymentSubscription'">{{ data.application_no }}</p>
    </div>
  </div>
  <div class="message-dialog__helper">
    أضغط على إيقونة الإغلاق (X) للإستمرار
  </div>
  <!-- close button  -->
  <div mat-dialog-actions class="message-dialog__content__buttons">
    <mat-icon
      [ngClass]="{payment: data.flag == 'payment'}"
      mat-dialog-close
      aria-hidden="false"
      aria-label="Example close icon"
      class="material-icons-outlined message-dialog__content__buttons__close"
      >close</mat-icon
    >
  </div>
</div>
