import {PaymentModule} from './../../payment/payment.module';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MaintenanceComponent} from 'src/app/shared/components/maintenance/maintenance.component';
import {NotFoundPageComponent} from 'src/app/ui/pages/not-found-page/not-found-page.component';
import {OzwaDocComponent} from 'src/app/ui/pages/ozwa-doc/ozwa-doc.component';
import {AuthGuard} from '../gaurds/auth.guard';
import {ServicesPageComponent} from 'src/app/services/pages/services-page/services-page.component';
import {SubscriptionSuccessComponent} from 'src/app/shared/components/subscription-success/subscription-success.component';
// import { ServicePlansPageComponent } from 'src/app/services/pages/service-plans-page/service-plans-page.component';

const routes: Routes = [
  //here will be the root routes for all the modules for achiveing lazy loading.
  {
    path: '',
    loadChildren: () =>
      import('../../case-type/case-type.module').then((m) => m.CaseTypeModule),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('../../auth/auth.module').then((m) => m.AuthModule),
    data: {isNavbarVisible: false},
    canActivate: [AuthGuard],
  },
  {
    path: 'inquiry',
    loadChildren: () =>
      import('../../inquiry/inquiry.module').then((m) => m.InquiryModule),
    data: {isNavbarVisible: true},
  },
  {
    path: 'cases',
    loadChildren: () =>
      import('../../cases/cases.module').then((m) => m.CasesModule),
    data: {isNavbarVisible: true},
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('../../payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'services',
    component: ServicesPageComponent,
  },
  // {
  //   path: 'services/:serviceId',
  //   component: ServicePlansPageComponent,
  // },
  {
    path: 'subscription-success/:serviceType/:applicationNum',
    component: SubscriptionSuccessComponent,
  },
  {
    path: 'info/:data',
    component: OzwaDocComponent,
    data: {isNavbarVisible: true},
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
  },
  {
    path: 'assistant',
    loadChildren: () =>
      import('../../assistant/assistant.module').then((m) => m.AssistantModule),
  },
  {
    path: '**',
    component: NotFoundPageComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
