export enum Region {
  Riyadh = 'منطقة الرياض',
  Makkah = 'منطقة مكة المكرمة',
  Madinah = 'منطقة المدينة المنورة',
  Eastern = 'المنطقة الشرقية',
  Tabuk = 'منطقة تبوك',
  Najran = 'منطقة نجران',
  Hail = 'منطقة حائل',
  NorthernBorders = 'منطقة الحدود الشمالية',
  Jouf = 'منطقة الجوف',
  Asir = 'منطقة عسير',
  Qassim = 'منطقة القصيم',
  Jazan = 'منطقة جازان',
  Baha = 'منطقة الباحة',
}

export enum ExemptionRequestReason {
  SOCIAL_SECURITY = 'ضمان اجتماعي',
  UNEMPLOYED = 'لا أعمل',
  DEFAULTED = 'متعثر',
  OTHER = 'أخرى',
}