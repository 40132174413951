<div class="plan">
  <div class="plan__info">
    <div class="plan__header">
      <h3 class="title">
        {{ plan.title }}
      </h3>
      <h2 class="price" [ngClass]="{'old-price': promoCode || !!autoDiscountAvailablity}">
        {{ plan.price }}
        <img *ngIf="!(promoCode || !!autoDiscountAvailablity)" 
          src="../../../../assets/images/Saudi_Riyal_Symbol.svg" alt="SR" class="sr-logo">
        <img *ngIf="(promoCode || !!autoDiscountAvailablity)" 
          src="../../../../assets/images/Saudi_Riyal_Symbol_lightGray.svg" alt="SR" class="sr-logo">
      </h2>
      <div *ngIf="promoCode">
        <h2 class="discounted-price">
          {{ discountedPrice }}
          <img src="../../../../assets/images/Saudi_Riyal_Symbol.svg" alt="SR" class="sr-logo">
        </h2>
        <div class="plan__header__discount-badge">
          قمت بتوفير %{{promoCode.discountPercentage}}
        </div>
      </div>
      <div *ngIf="!!autoDiscountAvailablity">
        <h2 class="discounted-price">
          {{ discountedPrice }}
          <img src="../../../../assets/images/Saudi_Riyal_Symbol.svg" alt="SR" class="sr-logo">
        </h2>
        <div class="plan__header__discount-badge">
          قمت بتوفير %{{autoDiscount}}
        </div>
      </div>
      <button mat-flat-button class="plan__header__purchase-btn" (click)="purchasePlan()">
        شراء
      </button>
    </div>

    <!-- Plan Features -->
    <div class="plan__body">
      <div class="plan__body__main-features">
        <div class="feature" *ngFor="let item of features">
          <span class="feature__icon">
            <mat-icon class="material-symbols-outlined" [ngClass]="{
              done: !!item.included,
              close: item.included == false,
              note: item.type == 'note'
            }">
              {{
              item.type == 'note'
              ? 'lens'
              : !!item.included && item.type == 'feature'
              ? 'done'
              : 'close'
              }}
            </mat-icon>
          </span>
          <p class="feature__info">
            {{ item.title }}
          </p>
        </div>
      </div>
      <div class="extend-btn-wrapper" #moreInfoBtn *ngIf="notes.length > 0">
        <button mat-flat-button class="extend-btn" (click)="toggleMoreInfo()">
          تعرف على المزيد
          <mat-icon>
            keyboard_arrow_down
          </mat-icon>
        </button>
      </div>

      <!-- Extendable card info -->
      <div class="plan__body__more-info" #moreInfo>
        <mat-divider *ngIf="features.length > 0"></mat-divider>
        <div class="feature feature__note" *ngFor="let item of notes">
          <mat-icon class="material-symbols-outlined note"> lens </mat-icon>
          <p class="feature__info">
            {{ item.title }}
          </p>
        </div>

        <button mat-flat-button class="extend-btn" (click)="toggleMoreInfo()">
          تفاصيل اقل
          <mat-icon>
            expand_less
          </mat-icon>
        </button>
      </div>
    </div>

    <!-- ***** Promo Code section ***** -->
    <mat-divider></mat-divider>
    <div class="plan__promo-code">
      <form class="plan__promo-code__wrapper" #promoCodeForm="ngForm">
        <h5 class="plan__promo-code__wrapper__header">
          كود خصم
        </h5>
        <div class="plan__promo-code__wrapper__input"
          [ngClass]="{'invalid-form': promoCodeState !== 'valid' && promoCodeState != ''}">
          <!-- Promo code input -->
          <div class="plan__promo-code__wrapper__input__box">
            <input type="text" placeholder="ادخل الكود" name="promoCode" [(ngModel)]="formPromoCode" required
              [disabled]="promoCode != null || !!autoDiscountAvailablity">
          </div>
          <!-- Form action buttons -->
          <!-- Apply Promo button -->
          <button mat-flat-button *ngIf="!promoCode && !promoLoading" type="submit"
            [disabled]="formPromoCode.length === 0" (click)="promoCodeSubmit(promoCodeForm)">
            تطبيق
          </button>
          <!-- Spinner button -->
          <button mat-flat-button *ngIf="promoLoading" class="plan__promo-code__wrapper__input__loader">
            <app-comment-spinner [spinnerColor]="'#CAA29A'" [spinnerScale]="'0.8'"></app-comment-spinner>
          </button>
          <!-- Remove promo button -->
          <button mat-flat-button *ngIf="promoCode" class="plan__promo-code__wrapper__input__remove-btn"
            (click)="removePromoCode()">
            إزالة
          </button>
        </div>
        <!-- Promo feedback messages -->
        <div class="plan__promo-code__wrapper__validation-msg" *ngIf="promoCodeState != ''">
          <div class="plan__promo-code__wrapper__validation-msg__item valid" *ngIf="promoCodeState == 'valid'">
            <mat-icon>check_circle_outline</mat-icon>
            تم تطبيق كود الخصم
          </div>
          <div class="plan__promo-code__wrapper__validation-msg__item invalid"
            *ngIf="promoCodeState === 'Coupon is expired'">
            <mat-icon>info_outline</mat-icon>
            الكود الذي أدخلته غير صالح، برجاء المحاولة مرة ثانية
          </div>
          <div class="plan__promo-code__wrapper__validation-msg__item invalid"
            *ngIf="promoCodeState == 'Coupon is not valid'">
            <mat-icon>info_outline</mat-icon>
            الكود الذي أدخلته غير صحيح، برجاء المحاولة مرة ثانية
          </div>
          <div class="plan__promo-code__wrapper__validation-msg__item invalid"
            *ngIf="promoCodeState == 'Coupon already used'">
            <mat-icon>info_outline</mat-icon>
            الكود الذي أدخلته مستخدم، برجاء المحاولة مرة ثانية
          </div>
        </div>
      </form>
    </div>

  </div>
</div>