<mat-toolbar *ngIf="navbarService.isNavbarVisible" class="navbar">
  <div class="menu">
    <!-- navbar logo  -->
    <div class="menu__logo">
      <div class="img" routerLink="/" >
        <img src="../../../../assets/images/logo.png" alt="logo" />
      </div>
      <div class="sperator"></div>
    </div>
    <!-- navbar links  -->
    <div class="menu__links" #overlay>
      <div class="menu__links_nav" #nav>
        <div class="menu__links_nav_header" *ngIf="this.navResponsive == true">
          <mat-icon
            (click)="makeItResponsive()"
            aria-hidden="false"
            aria-label="Example close icon"
            class="material-icons-outlined"
            >close</mat-icon
          >
          <div class="img" routerLink="/">
            <img src="../../../../assets/images/logoo.png" alt="logo" />
          </div>
        </div>
        <a
          class="link"
          *ngIf="auth.user.currentUser && this.navResponsive == true"
          routerLink="/user/my-profile"
          (click)="redirectToMyApplications('/user/my-profile')"
          >{{ auth.user.currentUser.username }}</a
        >
        <a
          class="link"
          *ngIf="!auth.user.currentUser && this.navResponsive == true"
          [routerLink]="['/user/login']"
          (click)="redirectToMyApplications('/user/login')"
          >تسجيل دخول</a
        >
        <a
          class="link"
          routerLink="/"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          (click)="redirectToMyApplications('/')"
          ><div>الرئيسية</div></a
        >
        <a
          class="link"
          (click)="redirectToMyApplications('/cases/my-cases')"
          routerLink="/cases/my-cases"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          ><div>القضايا</div></a
        >
        <a
          class="link"
          (click)="redirectToMyApplications('/inquiry/my-inquiries')"
          routerLink="/inquiry/my-inquiries"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          ><div>الاستشارات</div></a
        >

        <a
          class="link"
          (click)="redirectToMyApplications('/payment/my-payments')"
          routerLink="/payment/my-payments"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          ><div>المدفوعات</div></a
        >
        <a
          class="link"
          (click)="redirectToMyApplications('/services')"
          routerLink="/services"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: true}"
          ><div>الخدمات و الأسعار</div></a
        >
        <a
          class="link"
          (click)="goToLandingWebsite()"
          ><div>الموقع التعريفي</div></a
        >

        <div
          class="link logout"
          *ngIf="auth.user.currentUser && this.navResponsive == true"
          (click)="openLogoutDialog()"
        >
          <mat-icon
            class="material-icons-outlined"
            aria-hidden="false"
            aria-label="logout icon"
            fontIcon="logout"
            >logout</mat-icon
          >
          <p>تسجيل الخروج</p>
        </div>

        <figure class="menu__links_img" *ngIf="this.navResponsive == true">
          <img
            src="../../../../assets/svg/footer-page.svg"
            alt="consumer_navbar"
          />
        </figure>
      </div>

      <div class="responsive" #navResp>
        <mat-icon
          id="menu"
          class="material-icons-outlined"
          (click)="makeItResponsive()"
        >
          menu
        </mat-icon>
        <div class="img" routerLink="/">
          <img src="../../../../assets/images/logoo.png" alt="logo" />
        </div>
        <div class="responsive_icon">
          <mat-icon
            class="material-icons-outlined"
            *ngIf="auth.user.currentUser"
            routerLink="/user/my-profile"
          >
            account_circle
          </mat-icon>
          <mat-icon
            *ngIf="!auth.user.currentUser"
            [routerLink]="['/user/login']"
            (click)="redirectToMyApplications('/user/login')"
            class="material-icons-outlined"
            >logout</mat-icon
          >
          <mat-icon
            *ngIf="auth.user.currentUser"
            id="notify"
            class="material-icons-outlined"
            (click)="openNotification(this.navFlag)"
          >
            notifications
          </mat-icon>
          <div class="icon-counter" *ngIf="hasUnseenNotification > 0"></div>
        </div>
      </div>
    </div>
    <!-- navbar user toolset -->
    <div class="menu__user">
      <a *ngIf="!auth.user.currentUser" [routerLink]="['/user/login']"
        >تسجيل دخول</a
      >
      <a *ngIf="auth.user.currentUser" routerLink="/user/my-profile">{{
        auth.user.currentUser.username
      }}</a>
      <div class="icon-wrapper">
        <mat-icon
          id="notify"
          class="material-icons-outlined"
          (click)="openNotification(this.navFlag)"
        >
          notifications
        </mat-icon>
        <div class="icon-counter" *ngIf="hasUnseenNotification > 0">
          {{ hasUnseenNotification }}
        </div>
      </div>
      <div
        matTooltip="تسجيل الخروج"
        matTooltipPosition="above"
        matTooltipClass="tooltip-grey"
        mat-button
        class="icon-wrapper nav-logout"
        *ngIf="auth.user.currentUser"
        (click)="openLogoutDialog()"
      >
        <mat-icon
          class="material-icons-outlined"
          aria-hidden="false"
          aria-label="logout icon"
          fontIcon="logout"
          >logout</mat-icon
        >
      </div>

      <!-- <div class="disabled-feature">تواصل معانا</div> -->
    </div>
  </div>
</mat-toolbar>
