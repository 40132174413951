import { Subscription } from 'rxjs';
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { CaseTypeService } from 'src/app/case-type/services/case-type.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @ViewChild('openNotify') openNotify!: ElementRef;
  toggleOn: boolean = false;
  notifySub$!: Subscription;
  notifications: any = [];
  notificationsCount: any = [];
  notifyAllSub$!: Subscription;
  notifyCountSub$!: Subscription;
  notifySeenSub$!: Subscription;
  notifyOpenSub$!: Subscription;
  notifyNewSub$!: Subscription;
  infiniteScrollCounter: number = 10;
  hasMoreNotifications: boolean = true;
  notSeenNotifySub$!: Subscription;
  caseSub$!: Subscription;
  usercase!: any;
  constructor(
    private _notify: NotificationService,
    private _router: Router,
    private renderer: Renderer2,
    private _caseTypeService: CaseTypeService,
  ) {
    this.notifySub$ = this._notify.getCloseDialog().subscribe((res) => {
      if (res == 'close') {
        document.body.classList.remove('hidden');
        this.openNotify.nativeElement.style.display = 'none';
        this.openNotify.nativeElement.children[0].style.width = '0';
      } else {
        document.body.classList.add('hidden');
        this.openNotify.nativeElement.style.display = 'block';
        this.openNotify.nativeElement.children[0].style.width = '75rem';
      }
    });
    this.notifySeenSub$ = this._notify
      .getSeenNotification()
      .subscribe((data) => {
        if (!data)
          this.notifications.forEach((notification: any) => {
            notification.seen = true;
          });
      });
    this.renderer.listen('window', 'click', (e: any) => {
      if (
        !this.openNotify.nativeElement.children[0].contains(e.target) &&
        e.target?.id != 'notify'
      ) {
        this._notify.emitCloseDialog('close');
        this.toggleOn = false;
        document.body.classList.remove("hidden");
      }
    });
    this.notifyOpenSub$ = this._notify.getOpenDialog().subscribe((data) => {
      this.notifyAllSub$ = this._notify.getNotifications().subscribe((data) => {
        this.notifications = data;
        this.infiniteScrollCounter = 10;
        this.hasMoreNotifications = true;
      });
      this.notifyCountSub$ = this._notify
        .getNotificationsCount()
        .subscribe((data) => (this.notificationsCount = data));
    });
    this.notifyNewSub$ = this._notify.getNewNotification().subscribe((data) => {
      this._notify.getNotifications().subscribe((data) => {
        this.notifications = data;
        this.infiniteScrollCounter = 10;
        this.hasMoreNotifications = true;
      });
      this._notify.getNotificationsCount().subscribe((data) => {
        this.notificationsCount = data;
        this._notify.emitSeenNotification();
      });
    });
  }

  ngOnInit(): void { }

  goTo(app: string, id: number, notificationId: number) {
    document.body.classList.remove('hidden');
    this._notify.updateNotification(notificationId).subscribe((data: any) => {
      if (app == 'modify') {
        this.caseSub$ = this._caseTypeService
          .checkUserHasCase(id)
          .subscribe((res: any) => {
            if (res != null) {
              this.usercase = res;
              if (this.usercase.status == 'modify') {
                this._router
                  .navigateByUrl('/', { skipLocationChange: true })
                  .then(() => {
                    this._router.navigateByUrl(`/casetype/${id}`, {
                      //send usercase id, answers and modify object in route state
                      state: {
                        usercaseId: this.usercase.id,
                        usercaseAnswers: this.usercase.answers,
                        modifiyQuestions: this.usercase.modfiy_json,
                      },
                    });
                  });
              } else {
                this._router
                  .navigateByUrl('/', { skipLocationChange: true })
                  .then(() => {
                    this._router.navigateByUrl(
                      `/cases/details/${this.usercase.id}`,
                      {},
                    );
                  });
              }
            }
          });
      } else {
        this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this._router.navigateByUrl(
            `${app == 'case' ? `cases/details`
              : app == 'inquiry.evaluation.created'
                ? `inquiry/evaluation`
                : app == 'evaluation'
                  ? `cases/evaluation`
                  : `inquiry/details`}/${id}`,
          );
        });
      }
      this._notify.emitCloseDialog('close');
      this.toggleOn = false;
      this._notify.emitSeenNotification();
    });
  }

  // close notification
  close() {
    this._notify.emitCloseDialog('close');
    this.toggleOn = false;
    document.body.classList.remove('hidden');
  }

  loadMore() {
    if (this.toggleOn === true) {
      this.infiniteScrollCounter = 20;
      this.getNotSeenNotification();
    } else {
      this._notify
        .getNotificationsScroll(this.infiniteScrollCounter)
        .subscribe((data: any) => {
          if (data.length > 0) {
            this.notifications = [...this.notifications, ...data];
            this.infiniteScrollCounter += 10;
          } else {
            this.hasMoreNotifications = false;
          }
        });
    }
  }

  // toggle icon
  toggle(value: boolean) {
    this.toggleOn = value;
    this._notify.getNotifications().subscribe((data: any) => {
      this.notifications = value
        ? data.filter((notify: any) => {
          return notify.seen == false;
        })
        : data;
      if (value === true) {
        this.infiniteScrollCounter = 10;
        this.getNotSeenNotification();
      }
    });
  }
  // get not seen notification
  getNotSeenNotification() {
    this.notSeenNotifySub$ = this._notify
      .getNotificationSeen(this.infiniteScrollCounter)
      .subscribe((data: any) => {
        if (data.length > 0) {
          this.notifications = [...this.notifications, ...data];
          this.infiniteScrollCounter += 10;
        } else {
          this.hasMoreNotifications = false;
        }
      });
  }

  markAllNotificationsSeen() {
    this.notifySeenSub$ = this._notify.updateNotificationToSeen().subscribe({
      next: (res) => {
        this.notifications.forEach((notification: any) => {
          notification.seen = true;
        });
      },
    });
  }

  ngOnDestroy(): void {
    if (this.notifySub$) this.notifySub$.unsubscribe();
    if (this.notifyAllSub$) this.notifyAllSub$.unsubscribe();
    if (this.notifyCountSub$) this.notifyCountSub$.unsubscribe();
    if (this.notifySeenSub$) this.notifySeenSub$.unsubscribe();
    if (this.notifyOpenSub$) this.notifyOpenSub$.unsubscribe();
    if (this.notifyNewSub$) this.notifyNewSub$.unsubscribe();
    if (this.notSeenNotifySub$) this.notSeenNotifySub$.unsubscribe();
    if (this.caseSub$) this.caseSub$.unsubscribe();
  }
}
