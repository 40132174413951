import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {UserService} from '@kortobaa-front/authentication';
import {Subscription} from 'rxjs';
import {LogoutDialogComponent} from 'src/app/auth/dialogs/logout-dialog/logout-dialog.component';
import {CreateCaseComponent} from 'src/app/case-type/dialogs/create-case/create-case.component';
import {AuthService} from 'src/app/core/services/auth.service';
import {CheckLoginDialogComponent} from 'src/app/shared/dialogs/check-login-dialog/check-login-dialog.component';
import {CompleteProfileDialogComponent} from 'src/app/shared/dialogs/complete-profile-dialog/complete-profile-dialog.component';
import {NavbarService} from '../../service/navbar.service';
import {NotificationService} from '../notification/notification.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  navFlag: string = 'close';
  hasUnseenNotification!: number;
  notifySub$!: Subscription;
  @ViewChild('nav') nav!: ElementRef;
  @ViewChild('navResp') navResp!: ElementRef;
  @ViewChild('overlay') overlay!: ElementRef;
  public screenWidth: any = window.innerWidth;
  navResponsive: boolean = false;
  constructor(
    public navbarService: NavbarService,
    public auth: AuthService,
    private _router: Router,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _notify: NotificationService,
    private _user: UserService,
    private renderer: Renderer2,
  ) {
    // this._notify.emitCloseDialog(this.navFlag);
    this._notify.getCloseDialog().subscribe((data) => {
      this.navFlag = data;
    });
    if (this.screenWidth <= 800) {
      this.renderer.listen('window', 'click', (e: any) => {
        if (
          this.navResponsive == true &&
          !this.nav.nativeElement.contains(e.target) &&
          e.target?.id != 'menu'
        ) {
          // this.navResponsive = false
          // this.nav.nativeElement.style.display = "none"
          // this.renderer.removeClass(this.overlay.nativeElement, 'overlay');
          this.makeItResponsive();
        }
      });
    }
    this._user.checkUserValid().then((observable) =>
      observable.subscribe((data: any) => {
        if (data) {
          this._notify.getNotificationsCount().subscribe((data: any) => {
            this.hasUnseenNotification = data.count;
          });
        }
      }),
    );
    this._notify.getUserIsAuthticated().subscribe((data: any) => {
      this._notify.getNotificationsCount().subscribe((data: any) => {
        this.hasUnseenNotification = data.count;
      });
    });
    this._notify.getSeenNotification().subscribe((data: any) => {
      this._notify.getNotificationsCount().subscribe((data: any) => {
        this.hasUnseenNotification = data.count;
      });
    });
  }

  ngOnInit(): void {}

  //open create case dialog
  openCreateCase() {
    const dialogRef = this._dialog.open(CreateCaseComponent, {
      panelClass: 'white-dialog',
      width: '58rem',
      height: '39rem',
      autoFocus: false,
    });
  }

  //logout from and popup a snackbar notification
  logout() {
    this.auth.user.logout();
    this._router.navigateByUrl('');
    this._snackBar.open('تم تسجيل الخروج', 'X', {
      panelClass: 'error-snackbar',
      // duration: 2000,
      // verticalPosition: 'bottom',
    });
  }

  redirectToMyApplications(route: string) {
    const isUserLogged = this.auth.user.currentUser;
    if (this.screenWidth <= 800) {
      this.makeItResponsive();
    }
    if (isUserLogged) {
      const isProfileCompleted =
        this.auth.user.currentUser.isFinishedAdditionalStep;
      if (isProfileCompleted) {
        this._router.navigateByUrl(route);
      } else {
        if (this._dialog.openDialogs.length > 0) return;
        const dialogRef = this._dialog.open(CompleteProfileDialogComponent, {
          panelClass: 'white-dialog',
          // width: '64rem',
          // height: '70rem',
          width: '75rem',
          height: '80rem',
          autoFocus: false,
          data: {route: ''},
        });
      }
    } else {
      if (route === '/services' || route === '/') {
        this._router.navigateByUrl(route);
      } else {
        this._router.navigateByUrl('');
        const dialogRef = this._dialog.open(CheckLoginDialogComponent, {
          panelClass: 'white-dialog',
          width: '58rem',
          height: '39rem',
          data: {
            message:
              'برجاء تسجيل الدخول لاستعراض القضايا و الاستشارات الخاصة بك',
          },
        });
      }
    }
  }

  openNotification(value: any) {
    this.navFlag = this.navFlag == 'close' ? 'open' : 'close';
    this._notify.emitCloseDialog(this.navFlag);
    if (this.navFlag == 'open') {
      // this.notifySub$ = this._notify.updateNotificationToSeen().subscribe((data) => {
      //   this.hasUnseenNotification = false;
      //   this._notify.emitSeenNotification(false);
      // });
      this._notify.emitOpenDialog();
    }
  }

  makeItResponsive() {
    if (this.navResponsive == false) {
      this.navResponsive = true;
      this.nav.nativeElement.style.display = 'block';
      document.body.classList.add('hidden');
      this.overlay.nativeElement.classList.add('overlay');
    } else {
      this.navResponsive = false;
      this.nav.nativeElement.style.display = 'none';
      document.body.classList.remove('hidden');
      this.renderer.removeClass(this.overlay.nativeElement, 'overlay');
    }
  }

  openLogoutDialog(from?: string) {
    this._dialog.open(LogoutDialogComponent, {
      panelClass: 'white-dialog',
      width: '58rem',
      height: '39rem',
      autoFocus: false,
      data: {
        from: from,
      },
    });
    if (this.screenWidth <= 800) {
      this.makeItResponsive();
    }
  }

  goToLandingWebsite() {
    window.open('https://ezoah.com.sa', '_blank');
  }

  ngOnDestroy(): void {
    if (this.notifySub$) this.notifySub$.unsubscribe();
  }
}
