import { Usercase } from 'src/app/core/interfaces/usercase.interface';
import { Subscription } from 'rxjs';
import { Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { LatestUpdatesService } from './latest-updates.service';
import { GenerateFormDataService } from '../../services/generate-form-data.service';
import { ActivatedRoute } from '@angular/router';
import { LatestUpdate } from 'src/app/core/interfaces/case-type.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AnswerComplainDialogComponent } from 'src/app/cases/dialogs/answer-complain-dialog/answer-complain-dialog.component';
import { PreviewImagesComponent } from '../preview-images/preview-images.component';
import { AttorneyViewDialogComponent } from 'src/app/cases/dialogs/attorney-view-dialog/attorney-view-dialog.component';
import { paymentStages, PlanSubscription } from 'src/app/core/interfaces/service-plan';

@Component({
  selector: 'app-latest-updates',
  templateUrl: './latest-updates.component.html',
  styleUrls: ['./latest-updates.component.scss'],
})
export class LatestUpdatesComponent implements OnInit {
  attachFiles: any[] = [];
  MAXIMUM_IMAGE_SIZE: number = 2097152;
  isFilesInvalid: boolean | undefined = false;
  userCaseId!: number;
  latestUpdateSub$!: Subscription;
  @Input() type!: string;
  @Input() latestUpdate!: LatestUpdate[];
  @Input() hasRequest!: boolean;
  @Input() status!: string;
  @Input() userCase!: any;
  @Input() planSubscription!: PlanSubscription;
  @Input() openAcceptPaymentDialog!: (arg0?: string) => void;
  @Input() calcTotalAmount!: () => number;
  userId!: number;
  files!: File[];
  filesArr: any[] = [];
  description: string = '';
  @ViewChild('attachFilesInput') attachFilesInput!: ElementRef;
  commentLoad: boolean = false;
  commentId!: number;
  galleryArr: any = [];
  paymentStages = paymentStages;
  isActionLoading: boolean = false;
  commitmentDeclaration = [
    'أتعهد بأن أزود المحامية بالأوراق والمعلومات والمستندات الخاصة بالقضية خلال يومين عمل من تاريخ تقديم الطلب بالمنصة',
    'أتعهد بأن أفصح للمحامية عن جميع القضايا السابقة التي رفعتها أو كانت مرفوعة ضدي قبل تقديم الطلب، كما أتعهد أن أُطلع المحامية بجميع تفاصيل تلك القضايا والأوراق الخاصة بها خلال يومين عمل من تاريخ تقديم الطلب بالمنصة.',
    'في حال أقيمت دعوى جديدة ضدي بعد تقديم الطلب بالمنصة، وكانت مرتبطة بالقضية المطلوبة؛ فأتعهد بأن أبلغ المحامية على الفور بها.',
    'أتعهد بإبلاغ المحامية على الفور بأي مواعيد جلسات قضائية أو صلح في حينه سواء كانت تتعلق بالقضية نفسها أو أي قضية أخرى مرتبطة بها.',
    'أقر بعلمي بحق الجمعية في إغلاق الطلب والتوقف عن تقديم الخدمات القانونية لي في حال مخالفتي لأي من الالتزامات أعلاه',
  ];

  commitmentDeclaration2 = [
    'أتعهد بالامتناع عن اتخاذ أي إجراء قضائي يخص القضية المطلوبة أو يتعلق بإقامة دعوى جديدة أو إبرام صلح في موضوع مرتبط بالقضية المطلوبة قبل الرجوع للمحامية والأخذ بمرئياتها.',
    'أتعهد بالاستجابة لاستفسارات المحامية والتواصل معها بشكل فوري وسريع، وأقر بعلمي بأن للجمعية الحق في إغلاق الطلب مؤقتاً عند مرور 3 أيام عمل دون ردي على الرسالة التي تصلني من المحامية، كما يحق للجمعية إغلاق الطلب بشكل نهائي عند مرور 5 أيام عمل من تاريخ الإغلاق المؤقت دون تقديمي لطلب إعادة تفعيل الخدمة.',
    'أتعهد باتباع جميع توجيهات المحامية والأخذ بنصائحها وإرشاداتها والعمل بها وعدم مخالفتها.',
    'أقر بعلمي بحق الجمعية في إغلاق الطلب والتوقف عن تقديم الخدمات القانونية لي في حال مخالفتي لأي من الالتزامات أعلاه .',
  ];

  constructor(
    private _latestUpdatesService: LatestUpdatesService,
    private _generateFormData: GenerateFormDataService,
    private _activeRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public auth: AuthService,
    private _dialog: MatDialog,
  ) {
    this.getLatestUpdates();
    let userObj = JSON.parse(localStorage.getItem('UserProfile') || '{}');
    this.userId = userObj.id;
  }

  // ngOnChanges() {
  //   this.latestUpdate = this.latestUpdate.filter((item: any) => {
  //     if (
  //       item.roles_access.indexOf(this.auth.user.currentUser.roles[0]) !== -1
  //     ) {
  //       item.description = item.description.replace(
  //         /(https?:\/\/[^\s]+)/g,
  //         function (url: any) {
  //           item.link = true;
  //           return `<a class="link" href=${url} target="_blank"> ${url}</a>`;
  //         },
  //       );
  //       item?.replies?.map((reply: any) => {
  //         reply.description = reply.description.replace(
  //           /(https?:\/\/[^\s]+)/g,
  //           function (url: any) {
  //             return ` <a
  //           class="link"
  //           target="_blank"
  //           href="${url}"
  //           > ${url} </a
  //         > `;
  //           },
  //         );
  //       });
  //       return item;
  //     }
  //   });
  // }

  ngOnInit(): void { }

  // trigger the file input by click attch icon
  triggerUploadFiles(id: number): void {
    this.commentId = id;
    this.attachFilesInput.nativeElement.click();
  }

  //remove file from selected files before submitting
  removeFile(deletedFile: File, id: number): void {
    this.filesArr?.filter((file) => {
      file.comment_id === id
        ? (file.files = file.files.filter((item: File) => {
          return item != deletedFile;
        }))
        : '';
    });
  }

  //upload files to ready to submited with inquiry
  uploadFiles(event: any): void {
    const found = this.filesArr.every((el) => el.comment_id === this.commentId);

    this.files = event.target.files;

    this.files = [...this.files]?.filter((file, index) => {
      return file.size <= this.MAXIMUM_IMAGE_SIZE && index < 10;
    });

    if (this.filesArr.length > 0) {
      this.filesArr.filter((res) => {
        if (res.comment_id === this.commentId) {
          res.files.push(...this.files);
        } else if (!found) {
          let obj = { comment_id: this.commentId, files: [...this.files] };
          this.filesArr.push(obj);
        }
      });
    } else {
      let obj = { comment_id: this.commentId, files: [...this.files] };
      this.filesArr.push(obj);
    }

    this.filesArr?.filter((item) => {
      item.comment_id === this.commentId
        ? (item.files = item.files.filter((file: File, index: number) => {
          if (index == 9) {
            this._snackBar.open('حد أقصى عشر ملفات', 'X', {
              panelClass: 'error-snackbar',
              // verticalPosition: "bottom",
              // duration: 1500,
            });
          }
          return file.size <= this.MAXIMUM_IMAGE_SIZE && index < 10;
        }))
        : '';
    });
    this.filesArr = this.filesArr.filter(
      (a, i) =>
        this.filesArr.findIndex((s) => a.comment_id === s.comment_id) === i,
    );

    this.isFilesInvalid = [...this.files]?.every((file, index) => {
      return file.size >= this.MAXIMUM_IMAGE_SIZE;
    });

    this.attachFilesInput.nativeElement.value = '';
    this.commentId = 0;
  }

  // get all latest update from api
  getLatestUpdates() {
    this.userCaseId = Number(this._activeRoute.snapshot.paramMap.get('id'));
    this._latestUpdatesService.emitEvent(this.userCaseId);
  }

  validReplay(value: any) {
    this.description = value.control.value
      .replace(
        /[^/\n/\sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178\u0660-\u0669]{1,1000}/gm,
        '',
      )
      .trim();
    // this.description = e.target.value;
    return value.control.value
      .replace(
        /[^/\n/\sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178\u0660-\u0669]{1,1000}/gm,
        '',
      )
      .trim();
  }

  // add new replay and send to api
  addReplay(event: any, id: number) {
    if (this.description.length == 0) {
      return;
    }
    event.preventDefault();
    this.commentId = id;
    this.commentLoad = true;
    this.attachFiles = [];
    this.filesArr.filter((item) => {
      item.comment_id === id ? (this.attachFiles = item.files) : '';
    });

    if (this.description.length > 0 || this.attachFiles.length > 0) {
      const formData = this._generateFormData.generateFormData({
        description: this.description,
        comment_id: id,
        ...this.attachFiles,
      });

      this._latestUpdatesService.emitReplayEvent(formData);

      // replay load when data send to api
      this._latestUpdatesService.emitEventLoad(true);

      // if data it sent remove loading
      this._latestUpdatesService.getEventLoad().subscribe((data) => {
        // to remove after send replay
        if (data === false) {
          this.commentLoad = data;
          this.commentId = 0;
          this.description = '';
          this.attachFiles.map((file) => {
            this.removeFile(file, id);
          });
        }
      });
    }
  }

  openComplain(id: any) {
    this._dialog.open(AnswerComplainDialogComponent, {
      minHeight: '50rem',
      width: '92rem',
      maxWidth: '90vw',
      data: {
        complainId: +id,
      },
    });
  }

  // open gallery to preview
  openGallery(attachment: any, arr: any) {
    this.galleryArr = [];
    this.galleryArr.push(attachment.url);
    arr.filter((item: any) => {
      if (item.fileType == 'image' && item.url != attachment.url) {
        this.galleryArr.push(item.url);
      }
    });
    if (this.galleryArr.length >= 1) {
      this._dialog.open(PreviewImagesComponent, {
        panelClass: 'attachment-dialog',
        width: '100%',
        maxWidth: '100%',
        data: {
          arr: this.galleryArr,
        },
      });
    }
  }

  // open attorney dialog
  openAttorneyDialog() {
    this._dialog.open(AttorneyViewDialogComponent, {
      width: '88rem',
      minHeight: '40rem',
      maxWidth: '90vw',
      data: {
        userCase: this.userCase,
      },
    });
  }

  openInvoice(pdfUrl: any) {
    window.open(pdfUrl, '_blank');
  }

  respondToEndorsementRequest(
    commentId: number,
    action: string,
    endorsementType: string,
  ) {
    this.isActionLoading = true;
    const endorsementTitle =
      endorsementType == 'enforcement_declaration'
        ? '"النفاذ"'
        : endorsementType == 'commitment_declaration'
        ? '"التعهد"'
        : endorsementType == 'commitment_declaration_2'
        ? '"التعهد 2"'
        : '';
    const message = `تم ${
      action == 'approve' ? 'قبول' : 'رفض'
    } إقرار ${endorsementTitle} بنجاح`;

    this._latestUpdatesService
      .respondToEndorsementRequest(commentId, action)
      .subscribe({
        next: (res) => {
          this.isActionLoading = false;
          this._snackBar.open(message, '', {
            panelClass: 'success-snackbar',
            verticalPosition: 'bottom',
            duration: 1500,
          });
          this.getLatestUpdates();
        },
        error: (err) => {
          this.isActionLoading = false;
        }
      });
  }

  ngOnDestroy(): void {
    if (this.latestUpdateSub$) this.latestUpdateSub$.unsubscribe();
  }
}
