import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

//material modules
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatStepperModule} from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDividerModule} from '@angular/material/divider';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxAngularMaterialHijriAdapterModule} from 'ngx-angular-material-hijri-adapter';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

//components
import {MessageDialogComponent} from './dialogs/message-dialog/message-dialog.component';
import {CompleteProfileDialogComponent} from './dialogs/complete-profile-dialog/complete-profile-dialog.component';
import {ConfirmDialogComponent} from './dialogs/confirm-dialog/confirm-dialog.component';
import {CheckLoginDialogComponent} from './dialogs/check-login-dialog/check-login-dialog.component';
import {SearchDialogComponent} from './dialogs/search-dialog/search-dialog.component';
import {CommentSpinnerComponent} from './components/comment-spinner/comment-spinner.component';
import {CommentComponent} from './components/comment/comment.component';
import {LatestUpdatesComponent} from './components/latest-updates/latest-updates.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {HijriCalenderComponent} from './components/hijri-calender/hijri-calender.component';
import {PreviewImagesComponent} from './components/preview-images/preview-images.component';
import {OzwahSpinnerComponent} from './components/ozwah-spinner/ozwah-spinner.component';
import {BottomSheetComponent} from './components/bottom-sheet/bottom-sheet.component';

//pipes
import {SortObjectsPipe} from './pipes/sort-objects.pipe';
import {Min2hourPipe} from './pipes/min2hour.pipe';
import {ArabicDatePipe} from './pipes/arabic-date.pipe';
import {ArabicTimePipe} from './pipes/arabic-time.pipe';
import {HijriDatePipe} from './pipes/hijri-date';

//directives
import {ConfirmPasswordDirective} from './directives/confirm-password.directive';
import {TemplateFormValidatorDirective} from './directives/template-form-validator.directive';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { SubscriptionFlowStepperComponent } from './components/subscription-flow-stepper/subscription-flow-stepper.component';
import { SubscriptionSuccessComponent } from './components/subscription-success/subscription-success.component';
import { PaymentDetailsDialogComponent } from './dialogs/payment-details-dialog/payment-details-dialog.component';

@NgModule({
  declarations: [
    //pipes
    SortObjectsPipe,
    ArabicDatePipe,
    Min2hourPipe,
    ArabicTimePipe,
    HijriDatePipe,
    // directives
    ConfirmPasswordDirective,
    TemplateFormValidatorDirective,
    //components
    ConfirmDialogComponent,
    MessageDialogComponent,
    CompleteProfileDialogComponent,
    CheckLoginDialogComponent,
    SearchDialogComponent,
    PaginationComponent,
    CommentComponent,
    CommentSpinnerComponent,
    LatestUpdatesComponent,
    HijriCalenderComponent,
    PreviewImagesComponent,
    OzwahSpinnerComponent,
    BottomSheetComponent,
    MaintenanceComponent,
    SubscriptionFlowStepperComponent,
    SubscriptionSuccessComponent,
    PaymentDetailsDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    //material modules
    MatDividerModule,
    MatCardModule,
    MatIconModule,
    MatStepperModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    NgxAngularMaterialHijriAdapterModule,
    MatTooltipModule,
    RouterModule,
  ],
  exports: [
    //modules
    MatDialogModule,
    //pipes
    SortObjectsPipe,
    ArabicDatePipe,
    Min2hourPipe,
    ArabicTimePipe,
    HijriDatePipe,
    //directives
    ConfirmPasswordDirective,
    TemplateFormValidatorDirective,
    //components
    CompleteProfileDialogComponent,
    ConfirmDialogComponent,
    PaginationComponent,
    CommentComponent,
    LatestUpdatesComponent,
    CommentSpinnerComponent,
    HijriCalenderComponent,
    PreviewImagesComponent,
    OzwahSpinnerComponent,
    BottomSheetComponent,
    SubscriptionFlowStepperComponent,
  ],
  providers: [
    SortObjectsPipe,
    ArabicDatePipe,
    Min2hourPipe,
    ArabicTimePipe,
    HijriDatePipe,
  ],
})
export class SharedModule {}
