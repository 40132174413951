import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {NgForm} from '@angular/forms';
import {PlanFeature, ServicePlan} from 'src/app/core/interfaces/service-plan';
import {ArabicDatePipe} from 'src/app/shared/pipes/arabic-date.pipe';
import {AuthService} from 'src/app/core/services/auth.service';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {CheckLoginDialogComponent} from 'src/app/shared/dialogs/check-login-dialog/check-login-dialog.component';
import {PlanDetailsDialogComponent} from '../../dialogs/plan-details-dialog/plan-details-dialog.component';
import {PlanSubscriptionExistComponent} from '../../dialogs/plan-subscription-exist/plan-subscription-exist.component';

import {PromoCodeValidationService} from '../../service/promo-code-validation.service';
import {PromoCode} from 'src/app/core/interfaces/promo-code';
import {Subscription, finalize} from 'rxjs';
import {GenerateFormDataService} from 'src/app/shared/services/generate-form-data.service';
import {PaymentSubscriptionService} from 'src/app/shared/services/payment-subscription.service';
import {SnapPixelService} from 'src/app/shared/services/snap-pixel.service';

@Component({
  selector: 'app-plan-card',
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.scss'],
})
export class PlanCardComponent implements OnInit, OnDestroy {
  @Input() plan!: ServicePlan;
  @Input() serviceTitle!: string;
  @Input() serviceType!: string;
  @Input() hasEntityFlag!: boolean;
  @Input() autoDiscountAvailablity!: boolean | undefined;
  @Input() autoDiscount!: number | undefined;
  @Input() slug!: string;
  features: PlanFeature[] = [];
  notes: PlanFeature[] = [];
  formPromoCode: string = '';
  promoCode: PromoCode | null = null;
  promoCodeState: string = '';
  discountedPrice!: number;
  promoLoading: boolean = false;
  @ViewChild('moreInfo') moreInfo!: ElementRef;
  @ViewChild('moreInfoBtn') moreInfoBtn!: ElementRef;
  @ViewChild('promoCodeForm') promoCodeForm!: NgForm;
  entitySub$!: Subscription;
  paymentSub$!: Subscription;
  entityDataVar!: any;
  constructor(
    private _PromoCodeValidationService: PromoCodeValidationService,
    private _PaymentSubscriptionService: PaymentSubscriptionService,
    private _GenerateFormDataService: GenerateFormDataService,
    public auth: AuthService,
    private _router: Router,
    private _dialog: MatDialog,
    private _snapPixelService: SnapPixelService,
  ) {}

  ngOnInit(): void {
    this.filterItemsOfType();
    if (this.plan) {
      this.discountedPrice = this.plan.price;
    }
    if (this.autoDiscount) {
      this.discountedPrice = this.calcDiscount(this.autoDiscount);
    }
    // ******************************
    this.entitySub$ = this._PaymentSubscriptionService
      .getEntityObject()
      .subscribe((entityData) => {
        this.entityDataVar = entityData;
      });
  }

  days = [
    {
      value: 'sat',
      title: 'السبت',
    },
    {
      value: 'sun',
      title: 'الأحد',
    },
    {
      value: 'mon',
      title: 'الأتنين',
    },
    {
      value: 'tue',
      title: 'الثلاثاء',
    },
    {
      value: 'wed',
      title: 'الأربعاء',
    },
    {
      value: 'thu',
      title: 'الخميس',
    },
    {
      value: 'fri',
      title: 'الجمعة',
    },
  ];

  filterItemsOfType() {
    if (this.plan.planFeatures) {
      this.features = this.plan.planFeatures.filter((plan) => {
        return plan.type == 'feature';
      });
      this.notes = this.plan.planFeatures.filter((plan) => {
        return plan.type == 'note';
      });
    } else {
      this.features = [];
      this.notes = [];
    }
    if (this.plan.preFeesAmont) {
      this.notes.push({
        title: `لإتمام تقديم الطلب يتعين عليك دفع (${this.plan.preFeesAmont}) ريال وهي رسوم غير مستردة تمثل قيمة دراسة القضية من قبل المحامية، التي تتم من خلال التواصل معك لفحص القضية وتقييمها واعطائك المعلومات القانونية والنصائح الهامة لك. - عند قبول القضية بعد الانتهاء من دراستها، وفي حال رغبت بتوكيل المحامية للترافع عنك لحين صدور الحكم القضائي؛ فيتعين عليك دفع الرسوم المتبقية من الخدمة. `,
        type: 'note',
        planId: this.plan.id,
      });
    }
    if (this.plan.endsIn) {
      this.notes.push({
        title: `يرجى العلم أن مدة الاستفادة من الخدمة ${this.plan.endsIn} ${
          this.plan.endsInUnit == 'day'
            ? this.plan.endsIn >= 3 && this.plan.endsIn <= 10
              ? 'أيام'
              : 'يوم'
            : this.plan.endsInUnit == 'weak'
            ? this.plan.endsIn >= 3 && this.plan.endsIn <= 10
              ? 'اسابيع'
              : 'اسبوع'
            : this.plan.endsIn >= 3 && this.plan.endsIn <= 10
            ? 'شهور'
            : 'شهر'
        }  فقط من تاريخ تقديم الطلب ودفع قيمة الخدمة، حيث سيتم تحديد موعد الجلسة معك خلال هذه الفترة، لذا يرجى التجاوب مع طلبات المحامية خلال هذه الفترة والتأكد من مدى تفرغك لإتمام المطلوب منك قبل تقديم الطلب، ونعتذر عن تحديد موعد الجلسة بعد انتهاء الفترة. `,
        type: 'note',
        planId: this.plan.id,
      });
    }
    if (this.plan.availabilityDaysFrom) {
      this.notes.push({
        title: `يتم تحديد موعد الجلسة في أحد أيام الأسبوع من ${
          this.days.find((day) => {
            return day.value === this.plan.availabilityDaysFrom;
          })?.title
        } حتى ${
          this.days.find((day) => {
            return day.value === this.plan.availabilityDaysTo;
          })?.title
        } وخلال الفترة اليومية ما بين ${this.filterPipeTime.transform(
          this.plan.availabilityTimeFrom,
          'h:mm a',
        )} حتى ${this.filterPipeTime.transform(
          this.plan.availabilityTimeTo,
          'h:mm a',
        )}`,
        type: 'note',
        planId: this.plan.id,
      });
    }
  }
  filterPipeTime = new ArabicDatePipe();

  // Show/Hide extra card info
  toggleMoreInfo() {
    if (this.moreInfo.nativeElement.style.display === 'block') {
      this.moreInfo.nativeElement.style.display = 'none';
      this.moreInfoBtn.nativeElement.style.display = 'block';
    } else {
      this.moreInfo.nativeElement.style.display = 'block';
      this.moreInfoBtn.nativeElement.style.display = 'none';
    }
  }

  // Promo code actions
  promoCodeSubmit(promoCodeForm: NgForm) {
    const isUserLogged = this.auth.user.currentUser;
    if (isUserLogged) {
      this.promoLoading = true;

      this._PromoCodeValidationService
        .checkPromoCodeValidation(this.formPromoCode)
        .pipe(
          finalize(() => {
            this.promoLoading = false;
          }),
        )
        .subscribe({
          next: (value) => {
            this.promoCode = value;
            this.promoCodeState = 'valid';
            if (this.promoCode) {
              this.discountedPrice = this.calcDiscount(
                this.promoCode.discountPercentage,
              );
            }
          },
          error: (err) => {
            this.promoCodeState = err.error.error.message;
          },
        });
    } else {
      sessionStorage.setItem('last-step', '/services');
      this.openLoginDialog('تطبيق كود الخصم');
    }
  }

  removePromoCode() {
    this.promoCode = null;
    this.promoCodeState = '';
  }

  calcDiscount(percent: number) {
    return Math.floor(this.plan.price - (this.plan.price * percent) / 100);
  }

  // Plan Purchasing
  purchasePlan() {
    const isUserLogged = this.auth.user.currentUser;
    if (isUserLogged) {
      // Check for case/inquiry entity to bind
      if (this.hasEntityFlag) {
        this.purchasePlanAndBindEntity();
      } else {
        this.purchasePlanWithoutEntity();
      }
      //snap pixel
      this._snapPixelService.removeScript();
      this._snapPixelService.loadScript(
        this.auth.user.currentUser.email,
        'VIEW_CONTENT',
        {...this.plan, slug: this.slug},
      );
      sessionStorage.setItem('plan', JSON.stringify(this.plan));
    } else {
      sessionStorage.setItem('last-step', '/services');
      this.openLoginDialog('اتمام عملية الشراء');
    }
  }

  purchasePlanAndBindEntity() {
    const jsonObj = JSON.stringify(this.entityDataVar);
    let formData = this._GenerateFormDataService.generateFormData({
      planId: '' + this.plan.id,
      entity: jsonObj,
    });
    if (this.serviceType === 'inquiry') {
      this._PaymentSubscriptionService
        .getEntityFiles()
        .subscribe((entityFiles) => {
          formData = this._GenerateFormDataService.generateFormData({
            planId: '' + this.plan.id,
            entity: jsonObj,
            ...entityFiles,
          });
        });
    }
    if (this.promoCode) {
      formData.append('coupon', this.promoCode.title);
    }
    this._PaymentSubscriptionService
      .requestPaymentSubscription(formData)
      .subscribe({
        next: (data) => {
          this.openPlanDetailsDialog(
            data.subscription.id,
            data.subscription.preFees
              ? data.subscription.preFees
              : data.subscription.totalAmount,
            Object.keys(this.entityDataVar).length === 0 ? false : true, // Check presence of entity for navigation after request
          );
        },
        error: (error) => {
          if (error.error.error.statusCode === 400) {
            this.openSubscriptionExistDialog();
          } else {
            this._router.navigate(['']);
          }
        },
      });
  }

  purchasePlanWithoutEntity() {
    let formData = this._GenerateFormDataService.generateFormData({
      planId: '' + this.plan.id,
    });
    if (this.promoCode) {
      formData.append('coupon', this.promoCode.title);
    }
    this.paymentSub$ = this._PaymentSubscriptionService
      .requestPaymentSubscription(formData)
      .subscribe({
        next: (data) => {
          this.openPlanDetailsDialog(
            data.subscription.id,
            data.subscription.preFees
              ? data.subscription.preFees
              : data.subscription.totalAmount,
          );
        },
        error: (error) => {
          if (error.error.error.statusCode === 400) {
            this.openSubscriptionExistDialog();
          }
        },
      });
  }

  openSubscriptionExistDialog() {
    const dialogRef = this._dialog.open(PlanSubscriptionExistComponent, {
      panelClass: 'confirm-dialog',
      width: '58rem',
      height: '39rem',
      data: {
        title: 'لديك اشتراك بالفعل',
        serviceId: this.plan.serviceId,
        serviceType: this.serviceType,
        type: 'subscription',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'case') {
        this._router.navigate(['/casetype'], {
          queryParams: {serviceId: this.plan.serviceId},
        });
      } else {
        if (res === 'inquiry') {
          this._router.navigate(['/inquiry/create'], {
            queryParams: {serviceId: this.plan.serviceId},
          });
        }
      }
    });
  }

  openPlanDetailsDialog(
    subscriptionId: number,
    paymentAmount: number,
    hasEntity: boolean = false,
  ) {
    const dialogRef = this._dialog.open(PlanDetailsDialogComponent, {
      panelClass: 'confirm-dialog',
      width: '95rem',
      height: '75vh',
      data: {
        serviceTitle: this.serviceTitle,
        plan: this.plan,
        notes: this.notes,
        amount: paymentAmount,
        subscriptionId: subscriptionId,
        hasEntity: hasEntity,
      },
    });
  }

  openLoginDialog(message: string) {
    const dialogRef = this._dialog.open(CheckLoginDialogComponent, {
      panelClass: 'white-dialog',
      width: '58rem',
      height: '39rem',
      data: {
        message: `برجاء تسجيل الدخول للتمكن من ${message}`,
      },
    });
  }

  ngOnDestroy() {
    this.paymentSub$?.unsubscribe();
    this.entitySub$?.unsubscribe();
    this._dialog.closeAll();
  }
}
