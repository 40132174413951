<div class="complete-profile" *ngIf="currentStep === 1">
  <mat-icon aria-hidden="false" aria-label="close icon" class="material-icons-outlined close-icon"
    (click)="closeDialog()">close</mat-icon>
  <!-- complete profile title  -->
  <h2 class="complete-profile__title">
    تلك المعلومات مطلوبة لاستكمال الملف الشخصي معنا
  </h2>

  <!-- complete profile form  -->
  <form class="complete-profile__form" #completeProfile="ngForm" (ngSubmit)="submitProfile()">
    <div class="complete-profile__form__field field-file">
      <!-- martial status  -->
      <div class="field field-radio">
        <label class="field__label"> الحالة الاجتماعية </label>
        <mat-radio-group [(ngModel)]="maritalStatusValue" name="maritalStatus" #maritalStatus="ngModel">
          <mat-radio-button value="married">متزوج</mat-radio-button>
          <mat-radio-button value="divorced">مطلق</mat-radio-button>
          <mat-radio-button value="other">اخرى</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="field__content" style="margin-top: 4rem;">
        <label class="field__label">
          ارفاق صوره الهوية او البطاقة العائلية
          <span class="field__label__required">*</span>
        </label>

        <!-- upload button  -->
        <div class="upload-btn" *ngIf="!this.nidImageUrl">
          <button type="button" mat-button (click)="triggerUploadNidImage()">
            <span>ارفاق</span>
            <mat-icon aria-hidden="false" aria-label="Example attach_file icon"
              class="material-icons-outlined close-btn">attach_file</mat-icon>
          </button>
        </div>

        <!-- files badges wrapper  -->
        <div class="files-wrapper" *ngIf="this.nidImageUrl">
          <div class="file-badge">
            <span *ngIf="nidImage?.type == 'application/pdf'">PDF</span>
            <span *ngIf="
                nidImage?.type == 'image/png' ||
                nidImage?.type == 'image/jpg' ||
                nidImage?.type == 'image/jpeg'
              ">Image</span>
            <img *ngIf="nidImage?.type == 'application/pdf'" src="../../../../assets/images/attachment-pdf.png" />
            <img *ngIf="
                nidImage?.type == 'image/png' ||
                nidImage?.type == 'image/jpg' ||
                nidImage?.type == 'image/jpeg'
              " src="../../../../assets/images/attachment-img.png" />

            <mat-icon (click)="removeImage()" aria-hidden="false" aria-label="Example close icon"
              class="close-btn">close</mat-icon>
          </div>
        </div>
      </div>
      <p>برجاء التأكد من ان الصورة المرفقة واضحة و صحيحة</p>

      <!-- image preview  -->
      <!-- <div
        class="complete-profile__form__field__preview-image"
        *ngIf="this.nidImageUrl"
      >
        <img [src]="this.nidImageUrl" alt="uploaded image" />
        <mat-icon
          aria-hidden="false"
          aria-label="Example attach_file icon"
          class="material-icons-outlined close-btn"
          (click)="removeImage()"
          >close</mat-icon
        >
      </div> -->

      <!-- image input  -->
      <input (change)="onImgUploaded($event)" class="warning-border" style="display: none" ngModel name="image" required
        #nid_image type="file" accept="image/*" />
      <!-- warning  -->
      <p *ngIf="this.nidImageUrl == ''" class="complete-profile__form__field__warning">
        <mat-icon aria-hidden="false" aria-label="Example error icon" class="material-icons-outlined">error</mat-icon>
        بدون استكمال تلك الخطوة لا يمكن تقديم قضية او استشارة
      </p>
    </div>

    <!-- <mat-divider></mat-divider>

    <div class="complete-profile__form__field field-file">
      <div class="field__content">
        <label class="field__label">
          ارفاق صوره شخصية
        </label>

        <div class="upload-btn" *ngIf="!this.nidImageUrl">
          <button type="button" mat-button (click)="triggerUploadNidImage()">
            <span>ارفاق</span>
            <mat-icon
              aria-hidden="false"
              aria-label="Example attach_file icon"
              class="material-icons-outlined close-btn"
              >attach_file</mat-icon
            >
          </button>
        </div>

        <div class="files-wrapper" *ngIf="this.nidImageUrl">
          <div class="file-badge">
            <span *ngIf="nidImage?.type == 'application/pdf'">PDF</span>
            <span
              *ngIf="
                nidImage?.type == 'image/png' ||
                nidImage?.type == 'image/jpg' ||
                nidImage?.type == 'image/jpeg'
              "
              >Image</span
            >
            <img
              *ngIf="nidImage?.type == 'application/pdf'"
              src="../../../../assets/images/attachment-pdf.png"
            />
            <img
              *ngIf="
                nidImage?.type == 'image/png' ||
                nidImage?.type == 'image/jpg' ||
                nidImage?.type == 'image/jpeg'
              "
              src="../../../../assets/images/attachment-img.png"
            />

            <mat-icon
              (click)="removeImage()"
              aria-hidden="false"
              aria-label="Example close icon"
              class="close-btn"
              >close</mat-icon
            >
          </div>
        </div>
      </div>

      <input
        (change)="onImgUploaded($event)"
        class="warning-border"
        style="display: none"
        ngModel
        name="image"
        #nid_image
        type="file"
        accept="image/*"
      />
    </div> -->

    <!-- dialog button  -->
    <div class="complete-profile__form__buttons">
      <!-- submit button  -->
      <button
        [ngStyle]="{
          opacity:
            completeProfile.status == 'INVALID' || nidImageUrl == '' ? 0.6 : 1 || maritalStatusValue == '' || isSubmitting }"
        [disabled]="completeProfile.status == 'INVALID' || nidImageUrl == '' || maritalStatusValue == '' || isSubmitting"
        class="complete-profile__form__buttons__submit-btn" mat-button>
        {{ maritalStatusValue !== 'other'? 'متابعة' : 'تأكيد' }}
      </button>

      <!-- logout button  -->
      <button (click)="logout()" type="button" class="complete-profile__form__buttons__logout-btn" mat-button>
        تسجيل خروج
      </button>
    </div>
  </form>
</div>

<div class="complete-profile" *ngIf="currentStep === 2">
  <mat-icon aria-hidden="false" aria-label="close icon" class="material-icons-outlined close-icon"
    (click)="closeDialog()">close</mat-icon>
  <!-- complete profile title  -->
  <h2 class="complete-profile__title">تسجيل بيانات الزوج أو الزوجة</h2>

  <p class="complete-profile__info">
    قم بإدخال بيانات الزوج / الزوجة حتى تتمكن من الإستمتاع بخدمات المنصة
  </p>

  <!-- complete profile form  -->
  <form class="complete-profile__form" #spouseInfoForm="ngForm" (ngSubmit)="submitAddiontionalInfo()">
    <!-- Spouse name  -->
    <div class="field field-nid complete-profile__form__field">
      <label class="field__label">
        إسم الزوج / الزوجة
        <span class="field__label__required">*</span>
      </label>
      <mat-form-field appearance="outline">
        <input matInput ngModel required name="spouseName" #spouseName="ngModel" pattern="^[a-zA-Z\s\u0600-\u06ff]+$"
          onkeydown="return /[a-zA-Z\s\u0600-\u06ff]/i.test(event.key)" />
      </mat-form-field>
      <small class="field__alert-message"
        *ngIf="spouseName.touched && spouseName.errors && spouseName.errors['required']">
        هذا الحقل مطلوب
      </small>
      <small class="field__alert-message" *ngIf="
              spouseName.errors && spouseName.touched && spouseName.errors['pattern']">
        لا يمكن ادخال حروف مميزة او ارقام
      </small>
    </div>
    <!-- national id  -->
    <div class="field field-nid complete-profile__form__field">
      <label class="field__label">
        رقم الهوية / الإقامة / الزيارة
        <span class="field__label__required">*</span>
      </label>
      <mat-form-field appearance="outline">
        <input matInput type="number" [(ngModel)]="nidNumber" required name="nid" #nid="ngModel"
          [TemplateFormValidator]="nid" [validator]="validateSAID" inputmode="numeric" />
      </mat-form-field>
      <small class="field__alert-message" *ngIf="nid.errors && nid.touched && nid.dirty">
        تأكد بأن تكون رقم الهوية / الإقامة / الزيارة باللغه الانجليزية
      </small>
      <small class="field__alert-message" *ngIf="nid.touched && nid.errors && nid.errors['required']">
        هذا الحقل مطلوب
      </small>
      <small class="field__alert-message"
        *ngIf="!nid?.valid && nid.errors && nid.dirty && nid.touched && nid.errors['validator'] ">
        رقم الهوية / الإقامة / الزيارة الذي أدخلته غير صحيح يرجى إدخال الرقم الصحيح
      </small>
    </div>
    <!-- phone number -->
    <div class="field field-number complete-profile__form__field">
      <label class="field__label">
        رقم الجوال
      </label>
      <mat-form-field appearance="outline">
        <!-- //TODO refactor when create dedicated compoennet for phone numbers -->
        <span matSuffix>966+ &nbsp;</span>
        <input matInput [(ngModel)]="phoneNumberShow" type="number" name="phone" #phone="ngModel"
          maxlength="10" (keypress)="validNumber(phone)" (change)="validNumber(phone)" (input)="validNumber(phone)"
          (paste)="validNumber(phone)" pattern="^(?!966|00966)[0-9]{9}$" inputmode="numeric" />
      </mat-form-field>
      <small class="field__alert-message"
        *ngIf="phone.errors && phone.touched && phone.dirty && !phone.errors['required']">تأكد بأن يكون
        رقم الجوال باللغه الانجليزية
      </small>
      <small class="field__alert-message"
        *ngIf=" !phone?.valid && phone.touched && phone.errors && phone.errors['required']">هذا الحقل
        مطلوب</small>
      <small class="field__alert-message"
        *ngIf=" phone.errors && phone.dirty && phone.touched && phone.errors['pattern']">
        لا يمكن ادخال حروف مميزة <br />
        يجب ادخال 9 ارقام فقط<br />
        لا تبدأ ب 966 أو 00966
      </small>
    </div>

    <p class="complete-profile__form__field__info">
      <mat-icon aria-hidden="false" aria-label="info icon" class="material-icons-outlined">info</mat-icon>
      بعد ضغطك على تأكيد البيانات لا يمكنك تعديل بينات الزوج / الزوجة مرة أخرى
    </p>

    <div class="complete-profile__form__buttons">
      <!-- submit button  -->
      <button [ngStyle]="{ opacity: spouseInfoForm.status == 'INVALID' || isSubmitting ? 0.6 : 1}"
        [disabled]="spouseInfoForm.status == 'INVALID' || isSubmitting"
        class="complete-profile__form__buttons__submit-btn" mat-button>
        تأكيد
      </button>
    </div>
  </form>
</div>