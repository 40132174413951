import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@kortobaa-front/authentication';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InquiryService {
  constructor(private _http: HttpClient, private _user: UserService) { }

  userToken() {
    return {
      headers: { Authorization: `Bearer ${this._user.getToken()}` },
    };
  }

  createInquiry(formData: FormData): Observable<any> {
    return this._http.post<any>(
      `${environment.api_url}user/inquiries`,
      formData,
      this.userToken(),
    );
  }

  getInquiries(limit: number = 0, skip: number = 0): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/my-inquiries?filter={"limit":${limit} , "skip":${skip} , "order":["status ASC","updated_at DESC"]}`,
      this.userToken(),
    );
  }

  getInquiriesCount(): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/my-inquiries/count`,
      this.userToken(),
    );
  }

  getInquiriesById(id: number): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/inquiries/${id}`,
      this.userToken(),
    );
  }
  // check user has inquiry active
  checkInquiriesActive(): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/user-has-inquiry`,
      this.userToken(),
    );
  }

  //create comment
  createComment(commentBody: FormData): Observable<any> {
    return this._http.post(
      `${environment.api_url}user/inquiry-comments`,
      commentBody,
      this.userToken(),
    );
  }

  // get latest updates and comments from api by userCase id
  getLatestUpdates(userCaseId: number): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/inquiry-comments/${userCaseId}`,
      this.userToken(),
    );
  }

  // add replay to comment and post it to api
  createReply(formData: FormData): Observable<any> {
    return this._http.post(
      `${environment.api_url}user/inquiry-replies`,
      formData,
      this.userToken(),
    );
  }

  // add evaluation
  addEvaluation(response: any): Observable<any> {
    return this._http.post(
      `${environment.api_url}/user/inquiry-evaluations`,
      response,
      this.userToken(),
    );
  }

  // check inquiry has active complain
  checkComplainActive(id: number): Observable<any> {
    return this._http.get(
      `${environment.api_url}user/user-has-complain/${id}`,
      this.userToken(),
    );
  }
}
