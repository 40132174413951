<mat-form-field
  class="bodytext-normal full-width"
  block
  appearance="outline"
  color="primary"
  [class.modify]="classModify"
>
  <input
    matInput
    [max]="maxDate"
    [min]="minDate"
    [matDatepicker]="datePicker1"
    id="hijriDate"
    name="hijriDate"
    [placeholder]="placeholder"
    [(ngModel)]="value"
    autocomplete="off"
    [required]="required"
    (dateChange)="addDateHijri('hijriDate')"
    (click)="datePicker1.open()"
    readonly
    #hijriDate="ngModel"
    (ngModelChange)="emitAnswerChanged()"
  />

  <mat-datepicker-toggle
    class="custom-suffix"
    matSuffix
    [for]="datePicker1"
  ></mat-datepicker-toggle>
  <mat-datepicker #datePicker1></mat-datepicker>
</mat-form-field>
<small
  class="field__alert-message"
  *ngIf="
    !hijriDate?.valid &&
    hijriDate.touched &&
    hijriDate.errors &&
    hijriDate.errors['required']
  "
  >هذا الحقل مطلوب</small
>
