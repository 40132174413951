import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './core/routing/app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {environment} from 'src/environments/environment';

//modules
import {UiModule} from './ui/ui.module';
import {CoreModule} from './core/core.module';
import {Config, KAuthProvider} from '@kortobaa-front/authentication';

//material modules
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';

//components
import {MainComponent} from './ui/components/main/main.component';

//firebase config
import {initializeApp} from 'firebase/app';
import {ServicesModule} from './services/services.module';
import {AssistantInterceptor} from './assistant/services/assistant.interceptor';

initializeApp(environment.firebase);

@NgModule({
  declarations: [MainComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    UiModule,
    ServicesModule,

    //material modules
    MatSnackBarModule,
    MatDialogModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    HttpClient,
    {provide: KAuthProvider.default, useValue: {API_URL: environment.api_url}},
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        // duration: 1500,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: () => {
        const assistant = sessionStorage.getItem('assistantProfile');
        if (assistant) {
          return new AssistantInterceptor(true);
        } else {
          return new AssistantInterceptor(false);
        }
      },
      multi: true,
    },
  ],
  bootstrap: [MainComponent],
})
export class AppModule {}
