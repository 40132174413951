import {Inject, Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class AssistantInterceptor implements HttpInterceptor {
  constructor(@Inject('IS_ASSISTANT') private isAssistant: boolean = false) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.isAssistant) {
      request = request.clone({
        setHeaders: {
          assistant: 'true',
        },
      });
    }
    return next.handle(request);
  }
}
