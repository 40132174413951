import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GenerateFormDataService} from 'src/app/shared/services/generate-form-data.service';
import {ExemptionRequestService} from '../../service/exemption-request.service';
import {MatDialog} from '@angular/material/dialog';
import {MessageDialogComponent} from 'src/app/shared/dialogs/message-dialog/message-dialog.component';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/core/services/auth.service';
import {CheckLoginDialogComponent} from 'src/app/shared/dialogs/check-login-dialog/check-login-dialog.component';
import {PaymentSubscriptionService} from 'src/app/shared/services/payment-subscription.service';
import {Subscription, finalize} from 'rxjs';
import {UploadAttachmentsService} from 'src/app/shared/services/upload-attachments.service';
import {ExemptionRequestReason} from 'src/app/core/interfaces/enums';
import { PlanSubscriptionExistComponent } from '../../dialogs/plan-subscription-exist/plan-subscription-exist.component';

@Component({
  selector: 'app-exemption-request',
  templateUrl: './exemption-request.component.html',
  styleUrls: ['./exemption-request.component.scss'],
})
export class ExemptionRequestComponent implements OnInit {
  exemptionFiles: File[] | null | undefined = [];
  // MAXIMUM_IMAGE_SIZE: number = 10485760;
  MAXIMUM_IMAGE_SIZE: number = 5242880;
  isFilesInvalid: boolean | undefined = false;
  @Input() serviceId!: number;
  @Input() serviceType!: string;
  @ViewChild('exemptionForm') exemptionForm!: NgForm;
  @ViewChild('exemptionFilesInput') exemptionFilesInput!: ElementRef;
  @Input() hasEntityFlag!: boolean;
  isRequestLoading: boolean = false;
  paymentSub$!: Subscription;
  uploadAttachmentSub$!: Subscription;
  entityObject!: any;
  exemptionReason = Object.values(ExemptionRequestReason);
  reasonValue: string = '';
  constructor(
    private _snackBar: MatSnackBar,
    private _GenerateFormDataService: GenerateFormDataService,
    private _ExemptionRequestService: ExemptionRequestService,
    private _PaymentSubscriptionService: PaymentSubscriptionService,
    private _UploadAttachmentsService: UploadAttachmentsService,
    public auth: AuthService,
    private _router: Router,
    private _dialog: MatDialog,
  ) {
    this.paymentSub$ = this._PaymentSubscriptionService
      .getEntityObject()
      .subscribe((data) => {
        this.entityObject = data;
      });
  }

  ngOnInit(): void {
    // Check presence of entity for navigation after request
    Object.keys(this.entityObject).length === 0 ? (this.hasEntityFlag = false) : '';
  }

  submitExemptionRequest() {
    const isUserLogged = this.auth.user.currentUser;
    if (isUserLogged) {
      this.isRequestLoading = true;
      let attachmentsFormData = this._GenerateFormDataService.generateFormData({
        ...this.exemptionFiles,
      });
      this.uploadAttachmentSub$ = this._UploadAttachmentsService
        .uploadAttachments(attachmentsFormData)
        .subscribe({
          next: (uploadedFiles) => {
            // Check for case/inquiry entity to bind
            if (this.hasEntityFlag) {
              this.submitRequestWithEntity(uploadedFiles);
            } else {
              this.submitRequestWithoutEntity(uploadedFiles);
            }
          },
        });
    } else {
      sessionStorage.setItem('last-step', '/services');
      const dialogRef = this._dialog.open(CheckLoginDialogComponent, {
        panelClass: 'white-dialog',
        width: '58rem',
        height: '39rem',
        data: {
          message: 'برجاء تسجيل الدخول لتقديم طلب الإعفاء',
        },
      });
    }
  }

  submitRequestWithEntity(exemptionFilesArray: object[]) {
    const jsonObj = JSON.stringify(this.entityObject);
    let formData = this._GenerateFormDataService.generateFormData({
      serviceId: '' + this.serviceId,
      entity: jsonObj,
      attachments: JSON.stringify(exemptionFilesArray),
    });
    if (this.serviceType === 'inquiry') {
      this.paymentSub$ = this._PaymentSubscriptionService
        .getEntityFiles()
        .subscribe((entityFiles) => {
          if (entityFiles.length > 0) {
            formData = this._GenerateFormDataService.generateFormData({
              serviceId: '' + this.serviceId,
              ...entityFiles,
              entity: jsonObj,
              attachments: JSON.stringify(exemptionFilesArray),
            });
          }
        });
    }
    this._ExemptionRequestService
      .sendExemptionRequest(formData)
      .pipe(
        finalize(() => {
          this._PaymentSubscriptionService.setEntityObject(null);
          this._PaymentSubscriptionService.setEntityFiles([]);
        }),
      )
      .subscribe({
        next: (value) => {
          this.isRequestLoading = false;
          this.displayDialogAndNavigate();
        },
        error: (err) => {
          this.isRequestLoading = false;
          this._router.navigateByUrl('');
        },
      });
  }

  submitRequestWithoutEntity(exemptionFilesArray: any) {
    let formData = this._GenerateFormDataService.generateFormData({
      serviceId: '' + this.serviceId,
      reason: this.exemptionForm.form.value.reason,
      attachments: JSON.stringify(exemptionFilesArray),
    });
    if( this.exemptionForm.form.value.reason === ExemptionRequestReason.OTHER ) {
      formData.append('customReason', this.exemptionForm.form.value.customReason)
    }
    this._ExemptionRequestService.sendExemptionRequest(formData).subscribe({
      next: (value) => {
        this.isRequestLoading = false;
        this.displayDialogAndNavigate();
      },
      error: (err) => {
        this.isRequestLoading = false;
        if (err.error.error.statusCode === 400) {
          this.openSubscriptionExistDialog();
        }
      },
    });
  }

  // Navigate after request success
  displayDialogAndNavigate() {
    const dialogRef = this._dialog.open(MessageDialogComponent, {
      panelClass: 'payment-dialog-success',
      width: '87rem',
      height: 'auto',
      data: {
        flag: 'payment',
        message: 'تم تقديم طلب الإعفاء من الدفع بنجاح',
        type: 'exemption',
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      let navRoute;
      if (this.hasEntityFlag) {
        navRoute =
          this.serviceType === 'inquiry'
            ? '/inquiry/my-inquiries'
            : '/cases/my-cases';
      } else {
        navRoute =
          this.serviceType === 'inquiry' ? '/inquiry/create' : '/casetype';
      }
      this._router.navigate([navRoute], {
        queryParams: {serviceId: this.serviceId},
      });
    });
  }

  //trigger the file input by click attch icon
  triggerUploadFiles(): void {
    this.exemptionFilesInput.nativeElement.click();
  }

  //remove file from selected files before submitting
  removeFile(deletedFile: File): void {
    this.exemptionFiles = this.exemptionFiles?.filter(
      (file) => file != deletedFile,
    );
  }

  //upload files to ready to submited with inquiry
  uploadFiles(event: any): void {
    const files: File[] = event.target.files;
    this.exemptionFiles?.push(...files);

    this.isFilesInvalid = [...files]?.every((file, index) => {
      return file.size >= this.MAXIMUM_IMAGE_SIZE;
    });

    this.exemptionFiles = this.exemptionFiles?.filter((file, index) => {
      if (index == 10) {
        this._snackBar.open('حد أقصى عشر ملفات', 'X', {
          panelClass: 'error-snackbar',
        });
      }
      return file.size <= this.MAXIMUM_IMAGE_SIZE && index < 10;
    });

    this.exemptionFilesInput.nativeElement.value = '';
  }

  openSubscriptionExistDialog() {
    const dialogRef = this._dialog.open(PlanSubscriptionExistComponent, {
      panelClass: 'confirm-dialog',
      width: '58rem',
      height: '39rem',
      data: {
        title: 'لديك اشتراك بالفعل',
        serviceId: this.serviceId,
        serviceType: this.serviceType,
        type: 'subscription',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'case') {
        this._router.navigate(['/casetype'], {
          queryParams: {serviceId: this.serviceId},
        });
      } else {
        if (res === 'inquiry') {
          this._router.navigate(['/inquiry/create'], {
            queryParams: {serviceId: this.serviceId},
          });
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.paymentSub$) this.paymentSub$.unsubscribe();
    if (this.uploadAttachmentSub$) this.uploadAttachmentSub$.unsubscribe();
  }
}
