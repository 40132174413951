import {MainService} from './main-service';

export interface ServicePlan {
  id: number;
  title: string;
  price: number;
  preFeesAmont: number;
  enabled: boolean;
  endsIn: number;
  endsInUnit: string;
  hasPayments: boolean;
  availabilityDaysFrom: string;
  availabilityDaysTo: string;
  availabilityTimeFrom: string;
  availabilityTimeTo: string;
  created_at: string;
  updated_at?: string;
  serviceId: number;
  planFeatures: PlanFeature[];
  service?: MainService;
}

export interface PlanFeature {
  id?: number;
  title: string;
  included?: boolean;
  created_at?: string;
  updated_at?: string;
  type: string;
  planId: number;
  featureAccomplished?: boolean;
}

export interface PlanSubscription {
  id: number;
  status: string;
  paymentTier: string;
  planDetails: ServicePlan;
  amount: number;
  preFees: number;
  discount: number;
  remainingPaymentStatus?: string;
  upgradeServiceStatus?: string;
  paymentDate?: string;
  entityId: number;
  entityType: string;
  created_at: string;
  updated_at: string;
  planId: number;
  serviceId: number;
  userId: number;
  couponId?: number;
  couponDetails?: string;
  totalAmount?: number;
  vat?: number;
  discountAvailability?: boolean;
  upgradeAmount?: number;
  upgradeVat?: number;
  totalUpgradeAmount?: number;
  firstBatchAmount?: number;
  secondBatchAmount?: number;
  paymentStage?: string;
}

export enum paymentStages {
  none= 'none',
  preFeePaid = 'preFeePaid',
  firstBatchRequested = 'firstBatchRequested',
  firstBatchPaid = 'firstBatchPaid',
  secondBatchRequested = 'secondBatchRequested',
  secondBatchPaid = 'secondBatchPaid',
  oneTimePaid = 'oneTimePaid',
  upgradeServiceRequested = 'upgradeServiceRequested',
  upgradeServicePaid = 'upgradeServicePaid',
}