import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {PaginatorSetting} from 'src/app/core/interfaces/general.interface';
import {PaginationService} from './pagination.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() width: string = '100';
  @Input() pageSize: number = 5;
  paginatorSetting: PaginatorSetting = {
    length: 0,
    pageSize: 5,
    pages: 1,
    skip: 0,
  };
  @Input() resetPageIndex = false;
  @Output() pageChange = new EventEmitter<object>();
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;

  constructor(private _pagination: PaginationService) {}

  ngOnInit(): void {
    this.paginatorSetting.pageSize = this.pageSize;
    this._pagination.getEvent().subscribe((data) => {
      this.paginatorSetting.length = data;
      let totalPages = Math.ceil(this.paginatorSetting.length / this.pageSize);
      this.paginatorSetting.pages = totalPages <= 0 ? 1 : totalPages;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes['resetPageIndex']) {
      this.paginator.pageIndex = 0;
    }
  }

  //when click in prev or net button
  onPageButton(event: PageEvent): void {
    let totalPages = Math.ceil(event.length / event.pageSize);
    this.paginatorSetting.pages = totalPages <= 0 ? 1 : totalPages;
    this.onPageChange();
  }

  //when click in number button
  onPageNum(i: number): void {
    this.paginator.pageIndex = i;
    this.onPageChange();
  }

  //get data of this page
  onPageChange(): void {
    this.pageChange.emit({
      size: this.pageSize,
      index: this.paginator.pageIndex * this.pageSize,
    });
  }
}
