import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

import { ServicesPageComponent } from './pages/services-page/services-page.component';
import { ServicesListComponent } from './components/services-list/services-list.component';
import { CasestypesListComponent } from './components/casestypes-list/casestypes-list.component';
import { PlanCardComponent } from './components/plan-card/plan-card.component';
import { ServicePlansComponent } from './components/service-plans/service-plans.component';
import { ExemptionRequestComponent } from './components/exemption-request/exemption-request.component';

// Material Modules
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { PlanDetailsDialogComponent } from './dialogs/plan-details-dialog/plan-details-dialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PlanSubscriptionExistComponent } from './dialogs/plan-subscription-exist/plan-subscription-exist.component';
import { ServicePlansPageComponent } from './pages/service-plans-page/service-plans-page.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    ServicesPageComponent,
    ServicesListComponent,
    CasestypesListComponent,
    PlanCardComponent,
    ServicePlansComponent,
    ExemptionRequestComponent,
    PlanDetailsDialogComponent,
    PlanSubscriptionExistComponent,
    ServicePlansPageComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
  ]
})
export class ServicesModule { }
