import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { UserService } from '@kortobaa-front/authentication';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class LatestUpdatesService {
  constructor(private http: HttpClient, private _user: UserService) { }

  //create latest update event
  latestUpdate = new EventEmitter();

  //emit latest update value to latest update event
  emitEvent(value: any) {
    this.latestUpdate.emit(value);
  }

  //get event emitter to subscribe to
  getEvent() {
    return this.latestUpdate;
  }

  //create new Replay event
  addNewReplay = new EventEmitter();

  //emit new Replay value to latest update event
  emitReplayEvent(value: any) {
    this.addNewReplay.emit(value);
  }

  //get event emitter to subscribe to
  getReplayEvent() {
    return this.addNewReplay;
  }

  // comment Load

  //create comment Load event
  replayLoad = new Subject();

  //emit comment Load form to  event
  emitEventLoad(value: any) {
    this.replayLoad.next(value);
  }

  //get event emitter comment Load to subscribe to
  getEventLoad() {
    return this.replayLoad;
  }

  userToken() {
    return {
      headers: { Authorization: `Bearer ${this._user.getToken()}` },
    };
  }

  respondToEndorsementRequest(commentId: number, action: string) {
    return this.http.patch(
      `${environment.api_url}user/comments/${commentId}/endorsement`,
      { action: action },
      this.userToken(),
    );
  }
}
