<div class="payment-details-dialog">
    <mat-icon aria-hidden="false" aria-label="close icon" class="material-icons-outlined close-icon"
        (click)="closeDialog()">close</mat-icon>
    <h2 class="title">
        تفاصيل الدفع ({{data.serviceTitle}})
    </h2>
    <section class="case-details">
        <h3 class="section-title">
            <mat-icon class="material-symbols-outlined">featured_play_list</mat-icon>
            تفاصيل القضية
        </h3>
        <div class="case-details__row">
            <p>نوع القضية</p>
            <p> {{ data.caseTypeTitle }} رقم {{ data.applicationNumber }} </p>
        </div>
        <div class="case-details__row">
            <p>أسم المحامى</p>
            <p> {{ data.lawyerName }}</p>
        </div>
    </section>
    <mat-divider></mat-divider>
    <section class="payments-details">
        <h3 class="section-title">
            <mat-icon class="material-symbols-outlined">payments</mat-icon>
            تفاصيل الدفعات
        </h3>
        <div class="payments-details__row">
            <p class="row-title">
                <span class="row-bullet"></span>
                عربون
            </p>
            <p class="date" *ngIf="data.paymentDetails.subscriptionPayments[0]"> بتاريخ {{
                data.paymentDetails.subscriptionPayments[0].created_at | arabicDate: 'Do MMM yy'}} </p>
            <p> {{ data.paymentDetails.preFees }} ر.س</p>
        </div>
        <div class="payments-details__row" [class.fadedRow]="!data.paymentDetails.subscriptionPayments[1]">
            <p class="row-title">
                <span class="row-bullet"></span>
                دفعة أولى
            </p>
            <p class="date" *ngIf="data.paymentDetails.subscriptionPayments[1]"> بتاريخ {{
                data.paymentDetails.subscriptionPayments[1].created_at | arabicDate: 'Do MMM yy'}} </p>
            <p> {{ data.paymentDetails.firstBatchAmount }} ر.س</p>
        </div>
        <div class="payments-details__row" [class.fadedRow]="!data.paymentDetails.subscriptionPayments[2]">
            <p class="row-title">
                <span class="row-bullet"></span>
                دفعة ثانية
            </p>
            <p class="date" *ngIf="data.paymentDetails.subscriptionPayments[2]"> بتاريخ {{
                data.paymentDetails.subscriptionPayments[2].created_at | arabicDate: 'Do MMM yy'}} </p>
            <p> {{ data.paymentDetails.secondBatchAmount }} ر.س</p>
        </div>
    </section>
</div>