<div class="exemption-request" [ngClass]="{'toggled-style': toggleBtn.checked == true}">
  <mat-slide-toggle #toggleBtn>
    هل ترغب في طلب إعفاء من الدفع ؟
  </mat-slide-toggle>
  <div *ngIf="toggleBtn.checked" class="exemption-request__toggled">
    <p class="exemption-request__toggled__fileSize-info" [ngClass]="{'field__alert-message': isFilesInvalid}">
      لا يزيد حجم المرفق عن <span>5MB</span>
    </p>

    <form class="exemption-request__toggled__form" #exemptionForm="ngForm" (ngSubmit)="submitExemptionRequest()">
      <!-- exemption reason  -->
      <div class="exemption-reason">
        <div class="field field-radio">
          <label class="field__label"> أختر سبب طلب الأعفاء
            <span class="field__label__required">*</span>
          </label>
          <mat-radio-group [(ngModel)]="reasonValue" name="reason" #reason="ngModel" required>
            <mat-radio-button *ngFor="let reason of exemptionReason" [value]="reason"> {{ reason }} </mat-radio-button>
          </mat-radio-group>
        </div>
        <!-- custom reason  -->
        <div class="field" *ngIf="reasonValue === 'أخرى'">
          <mat-form-field appearance="outline">
            <input matInput ngModel required name="customReason" #customReason ="ngModel" maxlength="200"/>
          </mat-form-field>
          <small class="field__alert-message"
            *ngIf="customReason .touched && customReason .errors && customReason .errors['required']">
            هذا الحقل مطلوب
          </small>
        </div>
      </div>

      <p class="exemption-request__toggled__body-info">
        يرجي إرفاق ما يثبت عدم قدرتك على دفع الرسوم مثل إرفاق : (بطاقة الضمان االجتماعي، صك الإعسار، تقرير التعثر المالي
        من سمة،
        شهادة تثبت عجزها المالي من أي جمعية خيرية مرخصة بالمملكة, حالة الاشتراك من خلال خدمة التحقق من الاشتراك في نظام
        التأمينات الاجتماعية)
      </p>

      <!-- attach file  -->
      <div class="exemption-request__toggled__form__attach" (click)="triggerUploadFiles()"
        [ngStyle]="{ display: this.exemptionFiles && this.exemptionFiles.length > 0 ? 'none' : 'flex'}">
        <mat-icon aria-hidden="false" aria-label="Example attach icon"
          class="attach-btn material-icons-outlined">attach_file</mat-icon>
        <span> إرفق ملف </span>
      </div>
      <!-- file field  -->
      <div class="exemption-request__toggled__form__field-file">
        <input (change)="uploadFiles($event)" class="warning-border" style="display: none" name="image" required
          #exemptionFilesInput type="file" multiple accept="application/pdf,image/png, image/jpg, image/jpeg" />
      </div>

      <!-- files badges wrapper  -->
      <div class="exemption-request__toggled__form__files-wrapper">
        <div class="file-badge" *ngFor="let file of exemptionFiles">

          <span *ngIf="file.type == 'application/pdf'">PDF</span>
          <span *ngIf=" file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg' ">
            Image
          </span>
          <img *ngIf="file.type == 'application/pdf'" src="../../../../assets/images/attachment-pdf.png" />
          <img *ngIf="file.type == 'image/png' || file.type == 'image/jpg' || file.type == 'image/jpeg'"
            src="../../../../assets/images/attachment-img.png" />

          <mat-icon (click)="removeFile(file)" aria-hidden="false" aria-label="Example close icon"
            class="close-btn">close</mat-icon>
        </div>
        <img class="add-btn" *ngIf="this.exemptionFiles && this.exemptionFiles.length < 10"
          [ngStyle]="{ display: this.exemptionFiles && this.exemptionFiles.length > 0 ? 'block' : 'none' }"
          (click)="triggerUploadFiles()" src="../../../../assets/images/attach.png" alt="attach" />
      </div>
      <p class="exemption-request__toggled__form__files-wrapper__fileSize-info"
        [ngClass]="{'field__alert-message': isFilesInvalid}">
        لا يزيد حجم المرفق عن <span>5MB</span>
      </p>
      <button mat-flat-button class="submit-btn"
        [disabled]="exemptionForm.status == 'INVALID' || (exemptionFiles && exemptionFiles.length < 1) || isRequestLoading"
        type="submit" [ngStyle]="{display: !isRequestLoading ? 'block' : 'none'}">
        تقديم طلب الاعفاء
      </button>
      <button mat-flat-button class="submit-btn" type="button"
        [ngStyle]="{display: isRequestLoading ? 'block' : 'none', padding: '1rem 5rem'}" [disabled]="isRequestLoading">
        <app-comment-spinner></app-comment-spinner>
      </button>
    </form>
  </div>
</div>