import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/core/services/auth.service';
import {GenerateFormDataService} from 'src/app/shared/services/generate-form-data.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-complete-profile-dialog',
  templateUrl: './complete-profile-dialog.component.html',
  styleUrls: ['./complete-profile-dialog.component.scss'],
})
export class CompleteProfileDialogComponent implements OnInit {
  nidImage!: File | null;
  nidImageUrl: string | ArrayBuffer | null = '';
  MAXIMUM_IMAGE_SIZE: number = 2097152;
  isImageInvalid: boolean = false;
  SubmitProfileSub!: Subscription;
  @ViewChild('nid_image') nidImageInput!: ElementRef;
  currentStep: number = 1;
  maritalStatusValue = '';
  nidNumber: any;
  phoneNumber!: string;
  phoneNumberShow: any = '';
  @ViewChild('spouseInfoForm') spouseInfoForm!: NgForm;
  @ViewChild('completeProfile') completeProfile!: NgForm;
  completeProfileValue!: any;
  isSubmitting: boolean = false;
  constructor(
    private _generate: GenerateFormDataService,
    private _auth: AuthService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _dialogRef: MatDialogRef<CompleteProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    _dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  //upload image to submit the user profile
  onImgUploaded(event: any) {
    const uploadedImg = event.target.files[0];
    this.nidImage = uploadedImg;
    // Not allow to upload image which size is equal or more than 2MB
    this.isImageInvalid = uploadedImg.size >= this.MAXIMUM_IMAGE_SIZE;
    const reader = new FileReader();
    reader.readAsDataURL(uploadedImg);
    reader.onload = (_event) => {
      this.nidImageUrl = reader.result;
    };
  }

  //delete upload image before submitting
  removeImage() {
    this.nidImageUrl = '';
    this.nidImage = null;
    this.isImageInvalid = false;
    this.nidImageInput.nativeElement.value = '';
  }

  //trigger the file input by click attach icon
  triggerUploadNidImage() {
    this.nidImageInput.nativeElement.click();
  }

  //submit the photo and complete profile
  async submitProfile() {
    const formData = this._generate.generateFormData({
      maritalStatus: this.maritalStatusValue,
      image: this.nidImage,
    });
    if (this.maritalStatusValue !== 'other') {
      this.completeProfileValue = {
        maritalStatus: this.maritalStatusValue,
        image: this.nidImage,
      };
      this.currentStep = 2;
    } else {
      this.isSubmitting = true;
      await this._auth.compelteProfile(formData).then((data: any) => {
        this._snackBar.open('تم استكمال البيانات بنجاح', undefined, {
          panelClass: 'success-snackbar',
          duration: 1500,
        });
        const route = data?.route ? data?.route : '';
        this._router.navigateByUrl(route);
        this._dialogRef.close();
        this._auth.user
          .checkUserValid()
          .subsubcribe(() => {
            this._auth.user.currentUser.isFinishedAdditionalStep = true;
          })
          .catch((error: any) => {
            this.isSubmitting = false;
            this._dialogRef.close();
            this._router.navigateByUrl('');
          });
      });
    }
  }

  async submitAddiontionalInfo() {
    this.isSubmitting = true;
    this.completeProfileValue = {
      ...this.completeProfileValue,
      spouseName: this.spouseInfoForm.form.value.spouseName,
      spouseNid: this.spouseInfoForm.form.value.nid,
      // spousePhone: '+966' + this.phoneNumber,
    };

    if (this.phoneNumber) {
      this.completeProfileValue.spousePhone = '+966' + this.phoneNumber;
    }
    
    const formData = this._generate.generateFormData({
      ...this.completeProfileValue,
    });

    await this._auth.compelteProfile(formData).then((data: any) => {
      this._snackBar.open('تم استكمال البيانات بنجاح', undefined, {
        panelClass: 'success-snackbar',
        duration: 1500,
      });
      const route = data?.route ? data?.route : '';
      this._router.navigateByUrl(route);
      this._dialogRef.close();
      this._auth.user
        .checkUserValid()
        .subsubcribe(() => {
          this._auth.user.currentUser.isFinishedAdditionalStep = true;
        })
        .catch((error: any) => {
          this.isSubmitting = false;
          this._dialogRef.close();
          this._router.navigateByUrl('');
        });
    });
  }

  //logout from app
  logout() {
    this._auth.user.logout();
    this._router.navigateByUrl('');
    this._dialogRef.close();
    this._snackBar.open('تم تسجيل الخروج', 'X', {
      panelClass: 'error-snackbar',
      // duration: 2000,
      // verticalPosition: 'bottom',
    });
  }

  //validation function on saudi national id
  validateSAID(id: any) {
    id = id?.trim();
    if (Number(id) === null) {
      return false;
    }
    // visit id validation
    const typeVisitId = id.substr(0, 2);
    if (typeVisitId === '41' && id.length === 12) {
      return true;
    }
    if (id.length !== 10) {
      return false;
    }
    const type = id.substr(0, 1);
    if (type !== '2' && type !== '1') {
      return false;
    }
    let sum = 0;
    for (let i = 0; i < 10; i++) {
      if (i % 2 === 0) {
        const ZFOdd = String('00' + String(Number(id.substr(i, 1)) * 2)).slice(
          -2,
        );
        sum += Number(ZFOdd.substr(0, 1)) + Number(ZFOdd.substr(1, 1));
      } else {
        sum += Number(id.substr(i, 1));
      }
    }
    return sum % 10 !== 0 ? false : true;
  }

  //valid pattern
  validNumber(value: any) {
    if (value.control.value != null) {
      this.phoneNumber = value.control.value.toString().replace(/^0+/, '');
    }
  }

  closeDialog() {
    this._dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.SubmitProfileSub) this.SubmitProfileSub.unsubscribe();
    this.isSubmitting = false;
  }
}
