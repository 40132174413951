<section class="latest-updates__wrapper">
  <h4 class="latest-updates__wrapper_title">آخر التحديثات</h4>
  <div class="latest-updates__wrapper_content" *ngIf="latestUpdate && latestUpdate.length > 0">
    <ol class="latest-updates__wrapper_content_list">
      <li class="latest-updates__wrapper_content_list_item" *ngFor="let item of latestUpdate; let i = index">
        <div class="latest-updates__wrapper_content_list_item_circle circle" *ngIf="item.description.length >= 1">
          <p>
            {{ latestUpdate.length - i }}
          </p>
        </div>

        <!-- Endorsement response -->
        <div class="latest-updates__wrapper_content_list_item__comment evaluation_comment"
          *ngIf="item?.comment_type == 'approved_endorsement' || item?.comment_type == 'rejected_endorsement'">
          <h4 class="request-payment">
            لقد قمت {{ item?.comment_type == 'approved_endorsement' ? 'بقبول' : 'برفض'}} إقرار "{{ item.description }}" المطلوب من المحامي {{ item.userOwner["username"] }} بتاريخ {{item?.updated_at | arabicDate : 'Do MMM YYYY'}}
          </h4>
        </div>

        <!-- Request endorsement -->
        <div class="latest-updates__wrapper_content_list_item__answer-wrap" *ngIf="
                item?.comment_type == 'enforcement_declaration' ||
                item?.comment_type == 'commitment_declaration_2' ||
                item?.comment_type == 'commitment_declaration'
                " [ngStyle]="{
                margin: '5rem 1rem 0 0'}">
          <div class="latest-updates__wrapper_content_list_item__answer"
            [ngClass]="{you: item.userOwner['id'] === userId}">
            <!-- header comment -->
            <h4>
              <span class="latest-updates__wrapper_content_list_item__answer__title">
                قام المحامي بطلب عمل إقرار
              </span>
              <span></span>
              <span class="latest-updates__wrapper_content_list_item__replay-wrap_info__header__time endorsement-time">
                <mat-icon aria-hidden="false" class="material-icons-outlined">calendar_month</mat-icon>
                {{ item.created_at | arabicDate : 'Do MMM YYYY' }}
                <mat-icon aria-hidden="false" class="material-icons-outlined time">alarm_add</mat-icon>
                {{ item.created_at | arabicTime : 'h:mm a' }}
              </span>
              <span class="latest-updates__wrapper_content_list_item__answer__subtitle">
                طلب إقرار
                {{ item?.comment_type == 'enforcement_declaration' ? '"النفاذ"'
                : item?.comment_type == 'commitment_declaration' ? '"التعهد"'
                : item?.comment_type == 'commitment_declaration_2' ? '"التعهد 2"' : ''
                }}
              </span>
            </h4>
            <!-- divider -->
            <mat-divider class="latest-updates__wrapper_content_list_item__answer__divider divider"></mat-divider>
            <!-- description display -->
            <div class="latest-updates__wrapper_content_list_item__answer__desc" *ngIf="item.description.length > 0">
              <p *ngIf="item?.comment_type == 'enforcement_declaration'">
                أقر بموافقتي على تزويد المحامية التابعة لجمعية عزوة ببيانات النفاذ الوطني الخاصة بي، والسماح لها بالدخول
                على حسابي في بوابة ناجز للمتابعة القانونية الخاصة بقضيتي.
              </p>
              <ol *ngIf="item?.comment_type == 'commitment_declaration'">
                <li *ngFor="let item of commitmentDeclaration">
                  {{ item }}
                </li>
              </ol>
              <ol *ngIf="item?.comment_type == 'commitment_declaration_2'">
                <li *ngFor="let item of commitmentDeclaration2">
                  {{ item }}
                </li>
              </ol>

              <div mat-dialog-actions class="action-buttons">
                <button mat-button class="action-buttons__cancel" [disabled]="isActionLoading"
                  (click)="respondToEndorsementRequest(item.id, 'reject', item?.comment_type ?? '')">لا</button>
                <button mat-button class="action-buttons__confirm" [disabled]="isActionLoading"
                  (click)="respondToEndorsementRequest(item.id, 'approve', item?.comment_type ?? '')">نعم</button>
              </div>
            </div>

          </div>
        </div>

        <!-- Request remaining payment -->
        <div class="latest-updates__wrapper_content_list_item__comment evaluation_comment"
          *ngIf="item?.comment_type == 'request_remaining_payments'">
          <h4 class="request-payment" *ngIf="planSubscription.remainingPaymentStatus == 'requested'">
            قام المحامي ({{item?.userOwner?.username}}) بطلب دفع باقي التكلفة لإكمال القضية
            <span class="request-payment__action" (click)="openAcceptPaymentDialog()">المتابعة للدفع</span>
          </h4>
          <h4 class="request-payment" *ngIf="planSubscription.remainingPaymentStatus == 'paid'">
            شكراً، لقد قمت بدفع باقي التكلفة لاستكمال القضية بتاريخ {{item?.created_at | arabicDate : 'Do MMM'}}
          </h4>
        </div>

        <!-- first batch payment -->
        <div class="latest-updates__wrapper_content_list_item__comment evaluation_comment"
          *ngIf="item?.comment_type == 'request_first_batch'">
          <h4 class="request-payment" *ngIf="planSubscription.paymentStage === paymentStages.firstBatchRequested">
            قام المحامي ({{item?.userOwner?.username}}) بطلب دفع باقي (دفعة أولى) التكلفة لإكمال القضية
            <span class="request-payment__action" (click)="openAcceptPaymentDialog()">المتابعة للدفع</span>
          </h4>
          <h4 class="request-payment" *ngIf="planSubscription.paymentStage !== paymentStages.firstBatchRequested">
            شكراً، لقد قمت بدفع باقي التكلفة (دفعة أولى) لاستكمال القضية بتاريخ {{item?.created_at | arabicDate : 'Do MMM'}}
          </h4>
        </div>

        <!-- second batch payment -->
        <div class="latest-updates__wrapper_content_list_item__comment evaluation_comment"
          *ngIf="item?.comment_type == 'request_second_batch'">
          <h4 class="request-payment" *ngIf="planSubscription.paymentStage == paymentStages.secondBatchRequested">
            قام المحامي ({{item?.userOwner?.username}}) بطلب دفع باقي (دفعة ثانية) التكلفة لإكمال القضية
            <span class="request-payment__action" (click)="openAcceptPaymentDialog()">المتابعة للدفع</span>
          </h4>
          <h4 class="request-payment" *ngIf="planSubscription.paymentStage !== paymentStages.secondBatchRequested">
            شكراً، لقد قمت بدفع باقي التكلفة (دفعة ثانية) لاستكمال القضية بتاريخ {{item?.created_at | arabicDate : 'Do MMM'}}
          </h4>
        </div>

        <!-- Service upgrade payment -->
        <div class="latest-updates__wrapper_content_list_item__comment evaluation_comment"
          *ngIf="item?.comment_type == 'upgrade_service'">
          <h4 class="request-payment" *ngIf="planSubscription.paymentStage === paymentStages.upgradeServiceRequested">
            قام {{item?.userOwner?.roleId == 4 ? 'المشرف' : item?.userOwner?.roleId == 5? 'المحامي' : ''}}
            ({{item?.userOwner?.username}}) بإرسال طلب لترقية الخدمة بتاريخ {{item.created_at | arabicDate : 'Do MMM'}}
            <span class="request-payment__action" (click)="openAcceptPaymentDialog('upgrade')">المتابعة للدفع</span>
          </h4>
          <h4 class="request-payment" *ngIf="planSubscription.paymentStage !== paymentStages.upgradeServiceRequested">
            شكرًا، لقد قمت بدفع باقي التكلفة و تم ترقية الخدمة على القضية بتاريخ {{item.created_at | arabicDate : 'Do MMM'}}
          </h4>
        </div>

        <!-- evaluation comment  -->
        <div class="latest-updates__wrapper_content_list_item__comment evaluation_comment"
          *ngIf="item?.comment_type == 'evaluation'">
          <h4 *ngIf="!userCase?.evaluation">
            برجاء القيام بتقييم الخدمة المقدمة لك
            <a [routerLink]="type == 'case' ? ['/cases/evaluation/', userCaseId] : ['/inquiry/evaluation/', userCaseId]"
              alt="تقييم">
              من هنا
              <mat-icon aria-hidden="false" aria-label="arrow Add icon"
                class="material-icons-outlined">arrow_back</mat-icon>
            </a>
          </h4>
          <h4 *ngIf="userCase?.evaluation">
            لقد قمت بتقييم الخدمة المقدمة، شكراً
          </h4>
        </div>

        <!-- attorney comment  -->
        <div class="latest-updates__wrapper_content_list_item__comment alltorney_comment"
          *ngIf="item?.comment_type == 'power_of_attorney_request'">
          <h4>
            لقد قام المحامي ( {{ item.userOwner['username'] }} ) بطلب عمل توكيل،
            <span (click)="openAttorneyDialog()">
              خطوات عمل توكيل لمحامي جمعية عزوة
            </span>
          </h4>
        </div>

        <!-- cases of comments -->
        <div class="latest-updates__wrapper_content_list_item__comment" *ngIf="
            item?.comment_type == 'cancel_request' ||
            item?.comment_type == 'request_re_estimation' ||
            item?.comment_type == 'request_drop_usercase' ||
            item?.comment_type == 'temp-close' ||
            item?.comment_type == 'closed_usercase' ||
            item?.comment_type == 'finished_usercase' ||
            item?.comment_type == 'accept_cancel_request' ||
            item?.comment_type == 'failed_conciliation' ||
            item?.comment_type == 'success_conciliation' ||
            item?.comment_type == 'reject_inquiry' ||
            item?.comment_type == 'decline_cancel_request'
          " [ngStyle]="{
            padding:
              (item?.comment_type == 'cancel_request' ||
                item?.comment_type == 'temp-close' ||
                item?.comment_type == 'request_re_estimation' ||
                item?.comment_type == 'request_drop_usercase' ||
                item?.comment_type == 'finished_usercase' ||
                item?.comment_type == 'closed_usercase' ||
                item?.comment_type == 'reject_inquiry' ||
                item?.comment_type == 'failed_conciliation') &&
              item.description.length >= 1
                ? '1rem 5rem 1rem 0'
                : '1rem 5rem 5rem 0'
          }">
          <h4 *ngIf="
              item?.comment_type == 'cancel_request' ||
              item?.comment_type == 'reject_inquiry' ||
              item?.comment_type == 'temp-close' ||
              item?.comment_type == 'request_re_estimation' ||
              item?.comment_type == 'request_drop_usercase' ||
              item?.comment_type == 'closed_usercase' ||
              item?.comment_type == 'finished_usercase' ||
              item?.comment_type == 'accept_cancel_request' ||
              item?.comment_type == 'failed_conciliation' ||
              item?.comment_type == 'success_conciliation' ||
              item?.comment_type == 'decline_cancel_request'
            " [ngStyle]="{
              color:
                item?.comment_type != 'success_conciliation'
                  ? '#ab1212'
                  : '#080808'
            }">
            {{
            item?.comment_type == 'cancel_request'
            ? item.userOwner['id'] !== userId &&
            item.userOwner['roleId'] == 5
            ? ' لقد قام المحامي ' +
            item.userOwner['username'] +
            ' بإغلاق القضية نهائياً '
            : 'تم إغلاق القضية نهائياً'
            : item?.comment_type == 'temp-close'
            ? item.userOwner['id'] !== userId &&
            item.userOwner['roleId'] == 5
            ? ' لقد قام المحامي ' +
            item.userOwner['username'] +
            ' بإغلاق القضية مؤقتاً بتاريخ ' +
            (item.created_at | date : 'dd-MM-YYYY')
            : ' تم إغلاق القضية مؤقتاً بتاريخ ' +
            (item.created_at | date : 'dd-MM-YYYY')
            : item?.comment_type == 'closed_usercase'
            ? 'تم رفض القضية'
            : item?.comment_type == 'finished_usercase'
            ? item.userOwner['id'] !== userId &&
            item.userOwner['roleId'] == 5
            ? ' لقد قام المحامي ' +
            item.userOwner['username'] +
            ' بإغلاق القضية نهائياً '
            : 'تم إغلاق القضية نهائياً'
            : item?.comment_type == 'accept_cancel_request'
            ? 'تم قبول طلب التنازل عن القضية'
            : item?.comment_type == 'decline_cancel_request'
            ? 'تم رفض طلب التنازل عن القضية'
            : item?.comment_type == 'request_re_estimation'
            ? 'تم طلب إعادة تقدير التكلفة'
            : item?.comment_type == 'request_drop_usercase'
            ? 'تم طلب تنازل عن القضية'
            : item?.comment_type == 'success_conciliation'
            ? 'تم الصلح'
            : item?.comment_type == 'failed_conciliation'
            ? 'تعذر الصلح'
            : item?.comment_type == 'reject_inquiry'
            ?'قام المشرف ('+ item.userOwner["username"]+ ') برفض إستشارة (' + userCase.caseType.title + ') رقم (' +
            userCase.application_no + ')': ''
            }}
          </h4>
        </div>
        <!-- view reason and details of close case -->
        <div class="special-comment" [ngStyle]="{
            padding: item.description.length > 1 ? '1rem 5rem 0rem 0' : 0
          }" *ngIf="
            item?.comment_type == 'finished_usercase' ||
            item?.comment_type == 'cancel_request' ||
            item?.comment_type == 'temp-close' ||
            item?.comment_type == 'request_re_estimation' ||
            item?.comment_type == 'request_drop_usercase'||
            item?.comment_type == 'reject_inquiry'
          ">
          <p *ngIf="item.description.length > 1">
            <span>السبب : </span>{{ item.description }}
          </p>
        </div>
        <!-- view comment  -->

        <div class="latest-updates__wrapper_content_list_item__answer-wrap" *ngIf="
            (item?.comment_type != 'finished_usercase' &&
              item?.comment_type != 'cancel_request' &&
              item?.comment_type != 'temp-close' &&
              item?.comment_type != 'reject_inquiry' &&
              item?.comment_type != 'power_of_attorney_request' &&
              item?.comment_type != 'request_re_estimation' &&
              item?.comment_type != 'request_drop_usercase' &&
              item?.comment_type != 'accept_cancel_request' &&
              item?.comment_type != 'evaluation' &&
              item?.comment_type != 'decline_cancel_request' &&
              item?.comment_type != 'success_conciliation' &&
              item?.comment_type != 'request_remaining_payments' &&
              item?.comment_type != 'request_first_batch' &&
              item?.comment_type != 'request_second_batch' &&
              item?.comment_type != 'enforcement_declaration' &&
              item?.comment_type != 'commitment_declaration_2' &&
              item?.comment_type != 'commitment_declaration' &&
              item?.comment_type != 'approved_endorsement' && 
              item?.comment_type != 'rejected_endorsement' &&
              item?.comment_type != 'upgrade_service') ||
            ((item?.comment_type == 'closed_usercase' ||
              item?.comment_type == 'failed_conciliation') &&
              item.description.length > 1)
          " [ngStyle]="{
            margin:
              (item?.comment_type == 'cancel_request' ||
                item?.comment_type == 'closed_usercase' ||
                item?.comment_type == 'failed_conciliation') &&
              item.description.length > 1
                ? '2rem 5rem 5rem'
                : '5rem 1rem 0 0'
          }">
          <div class="latest-updates__wrapper_content_list_item__answer"
            [ngClass]="{you: item.userOwner['id'] === userId}">
            <!-- header comment -->
            <h4>
              <span class="latest-updates__wrapper_content_list_item__answer__title">
                {{
                item?.comment_type == 'failed_conciliation'
                ? 'سبب تعذر الصلح'
                : item?.comment_type == 'closed_usercase'
                ? 'سبب رفض القضية'
                : ''
                }}
                <ng-container *ngIf="
                    item?.comment_type != 'cancel_request' &&
                    item?.comment_type != 'temp-close' &&
                    item?.comment_type != 'request_re_estimation' &&
                    item?.comment_type != 'request_drop_usercase' &&
                    item?.comment_type != 'closed_usercase' &&
                    item?.comment_type != 'failed_conciliation'
                  ">
                  {{
                  item.userOwner['id'] === userId
                  ? 'أنت'
                  : item.userOwner['username'] &&
                  item.userOwner['roleId'] == 4
                  ? ''
                  : item.userOwner['username']
                  }}
                  {{
                  item.userOwner['id'] !== userId &&
                  item.userOwner['roleId'] == 4
                  ? ' المشرف '
                  : item.userOwner['id'] !== userId &&
                  item.userOwner['roleId'] == 5
                  ? ' (محامي) '
                  : item.userOwner['id'] !== userId &&
                  item.userOwner['roleId'] == 6
                  ? ' (مصلح) '
                  : ''
                  }}
                </ng-container>
              </span>

              <!-- <span
                class="latest-updates__wrapper_content_list_item__replay-wrap_info__header__time"
                *ngIf="item?.time_log"
              >
                <mat-icon aria-hidden="false" class="material-icons-outlined"
                  >alarm_add</mat-icon
                >
                الوقت المستغرق
                <span class="time">
                  {{ item?.time_log | min2hour : '' }}
                  {{ item?.time_log | min2hour : 'h' }}
                </span>
              </span> -->
              <span class="latest-updates__wrapper_content_list_item__replay-wrap_info__header__time">
              </span>
              <div class="edit-action">
                <span class="edit-date" *ngIf="item.updated_at && item.userOwner['roleId'] == 5">
                  مُعدل {{ item.updated_at | arabicTime : 'h:mm a' }}
                </span>
              </div>
              <span class="latest-updates__wrapper_content_list_item__answer__date" [ngStyle]="{}">
                <mat-icon aria-hidden="false" class="material-icons-outlined">calendar_month</mat-icon>
                {{ item.created_at | arabicDate : 'Do MMM' }}
                <mat-icon aria-hidden="false" class="material-icons-outlined">alarm_add</mat-icon>
                {{ item.created_at | arabicTime : 'h:mm a' }}
              </span>
            </h4>
            <!-- divider -->

            <mat-divider class="latest-updates__wrapper_content_list_item__answer__divider divider"></mat-divider>

            <!-- description display -->

            <div class="latest-updates__wrapper_content_list_item__answer__desc" *ngIf="item.description.length > 0">
              <p *ngIf="
                  item?.comment_type == 'complain_response' ||
                  item?.comment_type == 'create_complain'
                ">
                {{
                item?.comment_type == 'complain_response'
                ? 'تم الرد على الشكوي'
                : item?.comment_type == 'create_complain'
                ? 'تم تقديم شكوي'
                : null
                }}
                <span *ngIf="
                    item?.comment_type == 'complain_response' ||
                    item?.comment_type == 'create_complain'
                  " (click)="openComplain(item.comment_entities?.complainId)">اطلع عليها</span>
              </p>

              <p *ngIf="
                  item?.comment_type != 'complain_response' &&
                  item?.comment_type != 'create_complain'
                " [innerHTML]="item.description"></p>
            </div>

            <!-- comment attachment -->
            <div class="latest-updates__wrapper_content_list_item__answer__attachmentDisplay">
              <ng-container *ngFor="let attachment of item?.file_attachments">
                <div *ngIf="!attachment.deleted" (click)="
                                attachment.fileType == 'image'
                                  ? openGallery(attachment, item?.file_attachments)
                                  : openInvoice(attachment.url)
                              " class="latest-updates__wrapper_content_list_item__answer__attachmentDisplay__item">
                  <span *ngIf="attachment.fileType == 'application'">PDF</span>
                  <span *ngIf="attachment.fileType == 'image'">Image</span>
                  <img *ngIf="attachment.fileType == 'application'"
                    src="../../../../assets/images/attachment-pdf.png" />
                  <img *ngIf="attachment.fileType == 'image'" src="../../../../assets/images/attachment-img.png" />
                </div>
              </ng-container>
            </div>
            <!-- comment deleted attachment  -->
            <div class="latest-updates__wrapper_content_list_item__answer__deleted-attachments">
              <ng-container *ngFor="let attachment of item?.file_attachments; let i = index">
                <div *ngIf="!!attachment.deleted"
                  class="latest-updates__wrapper_content_list_item__answer__deleted-attachments__deleted-item">
                  <img *ngIf="attachment.fileType == 'application'"
                    src="../../../../assets/images/attachment-pdf.png" />
                  <img *ngIf="attachment.fileType == 'image'" src="../../../../assets/images/attachment-img.png" />
                  <span class="star">*</span>
                  تم حذف المرفق
                  {{ "(" + (i + 1) + ". " + (attachment.fileType == "application" ? "PDF)" : "image)") }} بواسطة المشرف
                  {{ attachment.deletedBy }} بتاريخ {{ attachment.deletedAt | arabicDate : "Do MMM YYYY" }}
                </div>
              </ng-container>
            </div>

            <!-- divider -->

            <mat-divider [ngStyle]="{
                display: status && status != 'temp-closed' ? 'block' : 'none'
              }" class="latest-updates__wrapper_content_list_item__answer__divider divider"></mat-divider>

            <!-- add new replay -->
            <div [ngStyle]="{
                display: status && status != 'temp-closed' ? 'block' : 'none'
              }" class="latest-updates__wrapper_content_list_item__answer__replay" [id]="i">
              <textarea [ngClass]="{
                  disabled:
                    (this.commentLoad && this.commentId == item.id) ||
                    (status &&
                      (status === 'closed' ||
                        status === 'cancelled' ||
                        status === 'finished' || status === 'rejected'))
                }" class="warning-border close-case-dialog__textarea" ngModel mat-input name="replay" #replay="ngModel"
                placeholder="إضافة رد" rows="1" (keypress)="validReplay(this.replay)" (paste)="validReplay(this.replay)"
                (input)="validReplay(this.replay)" (change)="validReplay(this.replay)"
                pattern="[/\n/\sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178\u0660-\u0669]{1,1000}$"
                oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' minlength="1"
                maxlength="1000" [disabled]="
                  status &&
                  (status === 'closed' ||
                    status === 'cancelled' ||
                    status === 'finished'|| status === 'rejected')
                ">
              </textarea>

              <input (change)="uploadFiles($event)" class="warning-border inputFile disabled" name="image" type="file"
                style="display: none" multiple #attachFilesInput max="10"
                accept="application/pdf,image/png, image/jpg, image/jpeg" />

              <div class="send-btn" [ngStyle]="{
                  display:
                    this.replay.control.value?.length > 0 ? 'flex' : 'none'
                }" [ngClass]="{
                  'load-btn': this.commentLoad && this.commentId == item.id
                }" (click)="addReplay($event, item.id)">
                <img [ngStyle]="{
                    display:
                      !this.commentLoad || this.commentId != item.id
                        ? 'block'
                        : 'none'
                  }" [src]="
                    item.userOwner['id'] === item.user_id
                      ? '../../../../assets/images/replayBtn.png'
                      : '../../../../assets/images/replayBtn_you.png'
                  " width="100" />
                <app-comment-spinner [ngStyle]="{
                    display:
                      this.commentLoad && this.commentId == item.id
                        ? 'block'
                        : 'none'
                  }"></app-comment-spinner>
              </div>
            </div>
          </div>
          <!-- comment message for files ma size  -->
          <div *ngIf="status && status != 'temp-closed'" class="latest-updates__wrapper_content_list_item__message"
            [ngStyle]="{
              display: this.replay.control.value?.length > 0 ? 'block' : 'none'
            }" [ngClass]="{
              disabled: this.commentLoad && this.commentId == item.id
            }">
            <small class="field__alert-message" *ngIf="
                !this.replay?.valid &&
                this.replay.errors &&
                this.replay.touched &&
                this.replay.dirty &&
                this.replay.errors['pattern']
              ">لا يمكن ادخال حروف مميزة</small>
            <div class="d-flex">
              <span [ngClass]="{
                  'field__alert-message':
                    this.replay.control.value?.length == 1000
                }" class="latest-updates__wrapper_content_list_item__message_charater">
                {{ this.replay.control.value?.length }}/1000 حرف
              </span>

              <span [ngClass]="{'field__alert-message': this.isFilesInvalid}">
                لا يزيد حجم المرفق عن 2Mb
              </span>
            </div>

            <div>
              <div class="latest-updates__wrapper_content_list_item__attach" (click)="triggerUploadFiles(item.id)"
                onclick='this.style.display = "flex";this.style.display = "none";'>
                <mat-icon aria-hidden="false" aria-label="Example attach icon"
                  class="attach-btn material-icons-outlined">attach_file</mat-icon>
                <span> إرفق ملف </span>
              </div>
              <!-- attachment display after add it in replay -->

              <ng-container *ngFor="let attach of filesArr">
                <div class="latest-updates__wrapper_content_list_item__answer__attachments" [ngStyle]="{
                    display:
                      this.files && this.filesArr && this.filesArr.length > 0
                        ? 'flex'
                        : 'none'
                  }" *ngIf="attach.comment_id === item.id">
                  <div class="file-badge" *ngFor="let file of attach.files">
                    <span *ngIf="file.type == 'application/pdf'">PDF</span>
                    <span *ngIf="
                        file.type == 'image/png' ||
                        file.type == 'image/jpg' ||
                        file.type == 'image/jpeg'
                      ">Image</span>
                    <img *ngIf="file.type == 'application/pdf'" src="../../../../assets/images/attachment-pdf.png" />
                    <img *ngIf="
                        file.type == 'image/jpeg' ||
                        file.type == 'image/png' ||
                        file.type == 'image/jpg'
                      " src="../../../../assets/images/attachment-img.png" />

                    <mat-icon (click)="removeFile(file, attach.comment_id)" aria-hidden="false"
                      aria-label="Example close icon" class="close-btn">close</mat-icon>
                  </div>
                  <div>
                    <div class="latest-updates__wrapper_content_list_item__attach"
                      *ngIf="this.filesArr && attach.files.length == 0" (click)="triggerUploadFiles(item.id)">
                      <mat-icon aria-hidden="false" aria-label="Example attach icon"
                        class="attach-btn material-icons-outlined">attach_file</mat-icon>
                      <span> إرفق ملف </span>
                    </div>

                    <img class="add-btn" *ngIf="this.filesArr && attach.files.length < 10" [ngStyle]="{
                        display:
                          this.filesArr && attach.files.length > 0
                            ? 'block'
                            : 'none'
                      }" (click)="triggerUploadFiles(item.id)" src="../../../../assets/images/attach.png"
                      alt="attach" />
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <!-- view replay -->
        <div *ngIf="item.replies && item.replies.length > 0" class="latest-updates__wrapper_content_list_item__replay">
          <div class="latest-updates__wrapper_content_list_item__replay-wrap_info"
            [ngClass]="{you: replay.userOwner['id'] === userId}" *ngFor="let replay of item.replies">
            <h4 class="latest-updates__wrapper_content_list_item__replay-wrap_info__header">
              <span class="latest-updates__wrapper_content_list_item__replay-wrap_info__header__title">{{
                replay.userOwner['id'] === userId
                ? 'أنت'
                : replay.userOwner['username'] &&
                replay.userOwner['roleId'] == 4
                ? ''
                : replay.userOwner['username']
                }}{{
                replay.userOwner['id'] !== userId &&
                replay.userOwner['roleId'] == 4
                ? ' المشرف '
                : replay.userOwner['id'] !== userId &&
                replay.userOwner['roleId'] == 5
                ? ' (محامي) '
                : replay.userOwner['id'] !== userId &&
                replay.userOwner['roleId'] == 6
                ? ' (مصلح) '
                : ''
                }}</span>
              <span class="latest-updates__wrapper_content_list_item__replay-wrap_info__header__time">
              </span>
              <div class="edit-action">
                <span class="edit-date" *ngIf="replay.updated_at && replay.userOwner['roleId'] == 5">
                  مُعدل {{ replay.updated_at | arabicTime : 'h:mm a' }}
                </span>
              </div>
              <span class="latest-updates__wrapper_content_list_item__replay-wrap_info__header__date">
                <mat-icon aria-hidden="false" class="material-icons-outlined">calendar_month</mat-icon>
                {{ replay.created_at | arabicDate : 'Do MMM' }}
                <mat-icon aria-hidden="false" class="material-icons-outlined">alarm_add</mat-icon>
                {{ replay.created_at | arabicTime : 'h:mm a' }}
              </span>
            </h4>
            <!-- divider -->

            <mat-divider class="latest-updates__wrapper_content_list_item__answer__divider divider"></mat-divider>

            <div class="latest-updates__wrapper_content_list_item__replay-wrap_info__desc"
              *ngIf="replay.description.length > 0">
              <p [innerHTML]="replay.description"></p>
            </div>

            <!-- comment attachment -->
            <div class="latest-updates__wrapper_content_list_item__answer__attachmentDisplay"
              *ngIf="replay?.file_attachments">
              <ng-container *ngFor="let attachment of item?.file_attachments">
                <div *ngIf="!attachment.deleted" (click)="
                                attachment.fileType == 'image'
                                  ? openGallery(attachment, replay?.file_attachments)
                                  : openInvoice(attachment.url)
                              " class="latest-updates__wrapper_content_list_item__answer__attachmentDisplay__item">
                  <span *ngIf="attachment.fileType == 'application'">PDF</span>
                  <span *ngIf="attachment.fileType == 'image'">Image</span>
                  <img *ngIf="attachment.fileType == 'application'"
                    src="../../../../assets/images/attachment-pdf.png" />
                  <img *ngIf="attachment.fileType == 'image'" src="../../../../assets/images/attachment-img.png" />
                </div>
              </ng-container>
            </div>
            <!-- comment deleted attachment  -->
            <div class="latest-updates__wrapper_content_list_item__answer__deleted-attachments">
              <ng-container *ngFor="let attachment of item?.file_attachments; let i = index">
                <div *ngIf="!!attachment.deleted"
                  class="latest-updates__wrapper_content_list_item__answer__deleted-attachments__deleted-item">
                  <img *ngIf="attachment.fileType == 'application'"
                    src="../../../../assets/images/attachment-pdf.png" />
                  <img *ngIf="attachment.fileType == 'image'" src="../../../../assets/images/attachment-img.png" />
                  <span class="star">*</span>
                  تم حذف المرفق
                  {{ "(" + (i + 1) + ". " + (attachment.fileType == "application" ? "PDF)" : "image)") }} بواسطة المشرف
                  {{ attachment.deletedBy }} بتاريخ {{ attachment.deletedAt | arabicDate : "Do MMM YYYY" }}
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </li>
    </ol>
  </div>
  <div *ngIf="latestUpdate.length == 0" class="latest-updates__wrapper__no-content">
    <img src="../../../../assets/images/not-found.png" alt="not found" />
    <p>لا يوجد أي تحديثات بعد</p>
  </div>
</section>